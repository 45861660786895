const Colors = [
  {
    title: "Multi",
    colorCode: "multi",
  },
  {
    title: "Black",
    colorCode: "#000000",
  },
  {
    title: "Dark Grey",
    colorCode: "#737373",
  },
  {
    title: "Light Grey",
    colorCode: "#d4d3d3",
  },
  {
    title: "Navy",
    colorCode: "#173864",
  },
  {
    title: "Med Blue",
    colorCode: "#567eb4",
  },
  {
    title: "Light Blue",
    colorCode: "#d6effd",
  },

  {
    title: "Aqua/Teal",
    colorCode: "#3AC6BE",
  },
  {
    title: "Green",
    colorCode: "#008037",
  },
  {
    title: "Honey",
    colorCode: "#ddac14",
  },
  {
    title: "yellow",
    colorCode: "#ffde59",
  },
  {
    title: "Coral",
    colorCode: "#fda97a",
  },
  {
    title: "Orange",
    colorCode: "#ff7a00",
  },
  {
    title: "Rust",
    colorCode: "#bd5406",
  },
  {
    title: "Red",
    colorCode: "#d80c0c",
  },
  {
    title: "Purple",
    colorCode: "#b146cf",
  },
  {
    title: "Pink",
    colorCode: "#ffb0f3",
  },
  {
    title: "Brown",
    colorCode: "#69530c",
  },
  {
    title: "Taupe",
    colorCode: "#9d825a",
  },
  {
    title: "Beige/Tan",
    colorCode: "#D8C481",
  },
  {
    title: "Ivory / Cearm",
    colorCode: "#ebe4cf",
  },
  {
    title: "White",
    colorCode: "#ffffff",
  },
];
export default Colors;
