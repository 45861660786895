import React, { useState, useEffect } from "react";
import { Loader, OrbitControls } from "@react-three/drei";
import { Canvas } from "@react-three/fiber";
import { connect, useDispatch, useSelector } from "react-redux";
import { CustomModal } from "../../common";
import ModalContent from "./ModalContent";
import { setModel } from "../../../redux/actions";
import { modelSelector } from "../../../utils";

function Preview() {
  const cushionState = useSelector((state) => state.configurations);
  const modelState = useSelector((state) => state.model);
  const [isFirstLoaded, setIsFirstLoaded] = useState(false);
  const [activeModel, setActiveModel] = useState(null);
  const dispatch = useDispatch();

  const getCushionMetaData = () => {
    var cushionType = cushionState.cushionType;
    var cushionShape = cushionState.cushionShape;
    if (
      cushionType === "" ||
      cushionType === null ||
      cushionShape === "" ||
      cushionType === null
    ) {
      return null;
    }
    return {
      cushionType: cushionType,
      cushionShape: cushionShape,
    };
  };
  const loadModel = () => {
    if (!isFirstLoaded) {
      // console.log("Before Model State:", modelState);
      var cushionData = getCushionMetaData();
      if (cushionData == null) {
        return null;
      }
      var modelComponent = modelSelector(
        cushionData.cushionType,
        cushionData.cushionShape
      );
      if (modelComponent === null) {
        return 0;
      } else {
        //🔄 Reload the model with hidden items
        modelComponent = modelSelector(
          cushionData.cushionType,
          cushionData.cushionShape,
          {
            hiddenItems: modelComponent.config.hiddenItems,
          }
        );
        dispatch(
          setModel({
            modelConfigurations: modelComponent.config,
            hiddenItems: modelComponent.config.hiddenItems,
          })
        );
      }
      setActiveModel(modelComponent.render);
      setIsFirstLoaded(true);
    } else {
      var cushionData = getCushionMetaData();
      var modelComponent = modelSelector(
        cushionData.cushionType,
        cushionData.cushionShape,
        {
          hiddenItems: modelState.hiddenItems,
          textures: modelState.fabrics,
        }
      );
      // console.log("🟢 Model Hidden Items:", modelState);
      setActiveModel(modelComponent.render);
    }
  };
  useEffect(() => {
    loadModel();
  }, [modelState.hiddenItems, modelState.fabrics]);

  return (
    <div className="preview-container">
      <div className="preview-content">
        <header className="preview-header">
          <div className="d-flex gap-0 align-items-center">
            <h1 className="fs-6 text-white m-0 p-0">Cushion Preview</h1>
            <CustomModal title="Cushion Preview">
              <ModalContent />
            </CustomModal>
          </div>
        </header>
        {activeModel && (
          <Canvas
            id="preview"
            camera={{ position: [2, 0, 12.25], fov: 15 }}
            style={{
              // backgroundColor: '#000',
              width: "100%",
              height: "100%",
            }}
          >
            <ambientLight intensity={1.25} />
            <ambientLight intensity={0.1} />
            <directionalLight intensity={0.4} />
            {/* <Suspense loader={<Loader />}> */}
            {activeModel}
            {/* </Suspense> */}
            <OrbitControls />
          </Canvas>
        )}
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    modelState: state.model,
  };
};
export default Preview;
