import React from "react";
import MeshTexture from "./MeshTexture";

export default function MeshPresenter(props) {
  const hasTexture =
    props.texture != null && props.texture != undefined ? true : false;
  // console.log("⭕ Has Texture: ", hasTexture, props.texture);
  return (
    <mesh castShadow receiveShadow {...props.item}>
      {hasTexture ? <MeshTexture texture={props.texture} /> : ""}
    </mesh>
  );
}
