const UID_PREFIX = "seat-squared-corners-";
const Configurations = {
  productTitle: "Seat Cushion",
  shape: "Rounded Corners",
  model: "SeatCushionModel",
  supportsDimensions: true,
  dimensions: {
    default: {
      thickness: 2,
      width: 20,
      depth: 20,
    },
    min: {
      thickness: 1,
      width: 1,
      depth: 1,
    },
    max: {
      thickness: 50,
      width: 50,
      depth: 50,
    },
  },
  hiddenItems: [
    "4_BACK_CORNER_TIES",
    "4_BACK_TIES_CENTERED",
    "4_FRONT_CORNER_TIES",
    "2_FRONT_TIES_CENTERED",
    "2_SIDE_TIES",
    "Seat_Rounded_Corners_Center_Seam_Pipe.001",
  ],
  configurations: [
    [
      {
        type: "fill-type",
        uid: `${UID_PREFIX}fill-type`,
      },
      {
        type: "ties-picker",
        title: "Ties",
        defaultValue: "none",
        uid: `${UID_PREFIX}ties`,
        objs: [
          "4_BACK_CORNER_TIES",
          "4_BACK_TIES_CENTERED",
          "4_FRONT_CORNER_TIES",
          "2_FRONT_TIES_CENTERED",
          "2_SIDE_TIES",
        ],
        options: [
          {
            title: "None",
            image: "/images/seat-cushions/ties/no.png",
            value: "none",
            ties: 0,
            objsShow: [],
          },
          {
            title: "All",
            image: "/images/seat-cushions/ties/all.png",
            value: "all",
            ties: 10,
            objsShow: [
              "4_BACK_CORNER_TIES",
              "4_BACK_TIES_CENTERED",
              "4_FRONT_CORNER_TIES",
              "2_FRONT_TIES_CENTERED",
              "2_SIDE_TIES",
            ],
          },
          {
            title: "Bottom",
            image: "/images/seat-cushions/ties/bottom.png",
            value: "bottom",
            ties: 2,
            objsShow: ["4_FRONT_CORNER_TIES"],
          },
          {
            title: "Top",
            image: "/images/seat-cushions/ties/top.png",
            value: "top",
            ties: 2,
            objsShow: ["4_BACK_CORNER_TIES"],
          },
          {
            title: "Side",
            image: "/images/seat-cushions/ties/side.png",
            value: "side",
            ties: 2,
            objsShow: ["2_SIDE_TIES"],
          },
        ],
      },
    ],
    [
      {
        type: "ct-picker",
        title: "Select Style",
        defaultValue: "",
        uid: "fabric-picker",
        cushionStructure: {
          configID: "",
          type: "img-choices",
          title: "Cushion Structure",
          defaultValue: "boxed",
          uid: "cushion-structure",
          objs: ["Seat_Rounded_Corners_Center_Seam_Pipe.001"],
          options: [
            {
              title: "Boxed",
              image: "/images/seat-cushions/cushion-structure/boxed.png",
              value: "boxed",
              objsShow: [],
            },
            {
              title: "Bull Nose",
              image: "/images/seat-cushions/cushion-structure/bull-nose.png",
              value: "bull-nose",
              objsShow: [],
            },
            {
              title: "Center Seam",
              image: "/images/seat-cushions/cushion-structure/center-seam.png",
              value: "center-seam",
              objsShow: ["Seat_Rounded_Corners_Center_Seam_Pipe.001"],
            },
          ],
        },
        options: [
          {
            title: "Fabric Side A",
            objs: [
              "Seat_Rounded_Corners_Center_Seam_Pipe.002",
              "4_BACK_CORNER_TIES",
              "4_BACK_TIES_CENTERED",
              "4_FRONT_CORNER_TIES",
              "2_FRONT_TIES_CENTERED",
              "2_SIDE_TIES",
            ],
            objsSideB: ["Seat_Rounded_Corners_Center_Seam_Bottom"],
          },
          {
            title: "Fabric Side B",
            objs: ["Seat_Rounded_Corners_Center_Seam_Bottom"],
          },
          {
            title: "Fabric Side C",
            objs: ["obj_1", "obj_2"],
          },
          {
            title: "Fabric Side D",
            objs: ["Seat_Rounded_Corners_Center_Seam_Pipe.001"],
          },
        ],
      },
    ],
  ],
};

export default Configurations;
