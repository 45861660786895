import React, { useState } from "react";
import {
  Badge,
  Col,
  Container,
  Dropdown,
  Nav,
  Navbar,
  Offcanvas,
  Row,
} from "react-bootstrap";
import { FaChevronDown, FaRegUserCircle } from "react-icons/fa";
import { BsCart3 } from "react-icons/bs";
import { useDispatch, useSelector } from "react-redux";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { logout } from "../../service";
import { toast } from "react-toastify";
import { logoutUser, resetCart } from "../../redux/actions";
import Loading from "react-fullscreen-loading";

export default function MainHeader() {
  const state = useSelector((state) => state.cart);
  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  //const user = JSON.parse(localStorage.getItem("user"));
  const [loading, setLoading] = useState(false);

  const onLogout = async () => {
    setLoading(true);
    try {
      let userId = user.id;
      let response = await logout({ userId: userId });

      if (response.data.success) {
        //localStorage.removeItem("user");
        dispatch(logoutUser());
        dispatch(resetCart());
        navigate("/");
        toast.success("Logged out successfully!");
        setLoading(false);
      }
    } catch (error) {
      toast.error("Error while logging out!");
      console.log(error);
      setLoading(false);
    }
  };

  return (
    <header className="main-header">
      <Loading loading={loading} background="#f8f9fa80" loaderColor="#000000" />
      <Container className="header-top py-2">
        <Row>
          <Col>
            <div className="d-flex align-items-center justify-content-between">
              <p className="text-body mb-0">
                Bespoke custom design for luxury outdoor living.
              </p>
              <div className="d-flex align-items-center gap-3">
                {!user?.token && <NavLink to="/login">Login</NavLink>}
                {user?.token && (
                  <Dropdown>
                    <Dropdown.Toggle className="text-body border-start rounded-0 py-0">
                      <FaRegUserCircle size={22} />
                      <FaChevronDown size={14} className="ms-2" />
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      <Dropdown.Item href="/dashboard">Dashboard</Dropdown.Item>
                      <Dropdown.Item href="#">My Profile</Dropdown.Item>
                      <button className="dropdown-item" onClick={onLogout}>
                        Logout
                      </button>
                    </Dropdown.Menu>
                  </Dropdown>
                )}
              </div>
            </div>
          </Col>
        </Row>
      </Container>
      <div className="header-mid py-2">
        <Container>
          <Row>
            <Col>
              <p className="text-center mb-0 text-uppercase fs-5">
                For a complimentary 30-min Design Consult Contact 469.933.0271
              </p>
            </Col>
          </Row>
        </Container>
      </div>
      <Navbar expand="md" className="header-bottom text-uppercase">
        <Container>
          <Navbar.Brand href="/">
            <img src="/images/logo-2.png" alt="Logo" width={260} />
          </Navbar.Brand>
          <div className="d-flex gap-3">
            <button
              className="cart-btn d-block d-md-none"
              onClick={() => navigate("/cart")}
            >
              <Badge bg="warning" className="rounded-circle">
                {state.cartItems.length}
              </Badge>
              <BsCart3 size={24} />
            </button>
            <Navbar.Toggle aria-controls="offcanvasNavbar-expand-md" />
          </div>
          <Navbar.Offcanvas
            id="offcanvasNavbar-expand-md"
            aria-labelledby="offcanvasNavbarLabel-expand-md"
            placement="end"
          >
            <Offcanvas.Header closeButton>
              <img src="/images/logo-2.png" alt="Logo" width={180} />
            </Offcanvas.Header>
            <Offcanvas.Body>
              <Nav className="justify-content-end flex-grow-1 pe-3">
                <Link to="/shop" className="text-body me-4">
                  Fabric Shop
                </Link>
                <Link to="/coming-soon" className="text-body">
                  Customize your cushion
                </Link>
              </Nav>
              <button
                className="cart-btn d-none d-md-block"
                onClick={() => navigate("/cart")}
              >
                <Badge bg="danger" className="rounded-circle">
                  {state.cartItems.length}
                </Badge>
                <BsCart3 size={24} />
              </button>
            </Offcanvas.Body>
          </Navbar.Offcanvas>
        </Container>
      </Navbar>
    </header>
  );
}
