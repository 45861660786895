import React from "react";
import { FaCheckCircle, FaCircle } from "react-icons/fa";

export default function CheckButton({ tick = false, title, onClick }) {
  return (
    <button
      className="btn btn-fill-type-batting d-flex my-4 p-0"
      onClick={onClick}
    >
      <div className="me-2">
        {!tick ? (
          <FaCircle className="text-secondary" />
        ) : (
          <FaCheckCircle className="text-primary" />
        )}
      </div>
      {title}
    </button>
  );
}
