import React from "react";
import { Button, Input } from "../common";
import { useFormik } from "formik";
import { loginSchema } from "../../utils/schema";
import { login } from "../../service";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { loginUser } from "../../redux/actions";

export default function LoginTab({ setLoading }) {
  const dispatch = useDispatch();
  const onLogin = async (values) => {
    setLoading(true);
    try {
      let response = await login(values);
      let user = response.data;
      //localStorage.setItem("user", JSON.stringify(user));
      dispatch(loginUser(user));
      toast.success("Logged in successfully!");
      setLoading(false);
    } catch (error) {
      toast.error("Error while logging in!");
      setLoading(false);
    }
  };
  const initialValues = {
    email: "",
    password: "",
  };
  const { values, handleChange, handleBlur, handleSubmit, touched, errors } =
    useFormik({
      initialValues: initialValues,
      validationSchema: loginSchema,
      onSubmit: onLogin,
    });
  return (
    <form onSubmit={handleSubmit}>
      <Input
        className="my-2"
        type="email"
        name="email"
        value={values.email}
        onChange={handleChange}
        onBlur={handleBlur}
        error={errors.email}
        touched={touched.email}
        label="Enter Email"
        placeholder="cushliving@example.com"
      />
      <Input
        className="my-2"
        type="password"
        name="password"
        value={values.password}
        onChange={handleChange}
        onBlur={handleBlur}
        error={errors.password}
        touched={touched.password}
        label="Enter Password"
        placeholder="Password"
      />
      <div className="mb-2">
        <Button type="submit" className="my-2">
          Log in
        </Button>
      </div>
    </form>
  );
}
