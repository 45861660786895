import "bootstrap/dist/css/bootstrap.min.css";
import "react-toastify/dist/ReactToastify.css";
import "./App.scss";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

//Redux
import { Provider } from "react-redux";
import store from "./redux/store";

//Pages
import {
  PgCushionSelector,
  PgCushionShapesSelector,
  PgThreeDRenderer,
  PgSuccessCart,
  Login,
  Home,
  Shop,
  SingleProduct,
  Cart,
  Checkout,
  Dashboard,
  Signup,
  ComingSoon,
} from "./pages";
import { Guest, ScrollToTop } from "./components";
import { ToastContainer } from "react-toastify";

function App() {
  return (
    <>
      <Provider store={store}>
        <Router>
          <ScrollToTop />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/cushion-designer" element={<PgCushionSelector />} />
            <Route
              path="/login"
              element={
                <Guest>
                  <Login />
                </Guest>
              }
            />
            <Route
              path="/shapes/:title"
              element={<PgCushionShapesSelector />}
            />
            <Route path="/3d-designer" element={<PgThreeDRenderer />} />
            <Route path="success-cart" element={<PgSuccessCart />} />
            <Route path="/shop" element={<Shop />} />
            <Route path="/shop/:productId" element={<SingleProduct />} />
            <Route path="/cart" element={<Cart />} />
            <Route path="/checkout" element={<Checkout />} />
            <Route path="dashboard" element={<Dashboard />} />
            <Route path="/signup" element={<Signup />} />
            <Route path="/coming-soon" element={<ComingSoon />} />
          </Routes>
        </Router>
        <ToastContainer />
      </Provider>
    </>
  );
}

export default App;
