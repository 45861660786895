import React from "react";
import MeshPresenter from "../3d-render/model/MeshPresenter";

export default function ModelBase({ modelProps, meshList }) {
  // console.log("🚀 Model Props:", modelProps);
  return (
    <>
      <group {...modelProps} dispose={null}>
        {meshList.map((item, i) => {
          var found = false;
          if (modelProps.hiddenItems != undefined) {
            found = modelProps.hiddenItems.includes(item.materialIndex);
          }
          if (found) {
            //   console.log('🟢 Material found!');
            return "";
          }
          var texture = null;
          // console.log(modelProps.textures);
          found = null;
          if (modelProps.textures !== undefined) {
            // console.log("🚀 Hi, I have tetxures!", modelProps.textures);
            found = modelProps.textures.find((textureData) => {
              if (textureData.material === item.materialIndex) {
                // console.log("🚀 Texture Matching:", textureData);
                return true;
              }
              return false;
            });
            // console.log("🚀 Texture:", found);
          }
          if (found != undefined && found != null) {
            // console.log("Texture Image:", found);
            texture = found.image;
          }
          return <MeshPresenter key={i} item={item} texture={texture} />;
        })}
      </group>
    </>
  );
}
