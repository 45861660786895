import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { setActiveFabric, setTexture } from "../../../../redux/actions";

export default function ActiveFabric({
  data,
  fabricSetter,
  handleShow,
  modelData,
}) {
  const brand = data.brands[0];
  const brandName = data.brands[0].title;
  const imagePath = `/images/fabric-gallery/${brandName}/${data.title}.jpg`;

  const model = useSelector((state) => state.model);
  const dispatch = useDispatch();

  const patterns = data.patterns.map((pattern) => {
    return pattern.title;
  });

  const useFabric = () => {
    // dispatch(setTexture(buttonTitle, activeFabric.data.img, data));
    handleShow();
    fabricSetter(data);
    // imgSetter(imagePath);
    // console.log("🚀 Fabric Data:", data);
    // fabricSetter(data);
    // console.log(model);
    dispatch(setActiveFabric(false, null));
    // console.log("🟢 Model Fabrics...", model, modelData);
    let modelFabrics = model.fabrics;
    let texture = modelData.objs.map((item) => {
      return {
        material: item,
        image: imagePath,
      };
    });
    if (modelFabrics.length > 0) {
      const merged = [...texture, ...modelFabrics].reduce((acc, obj) => {
        const { material, image, model } = obj;
        const existing = acc[material] || { material, image, models: [] };
        const updated = {
          ...existing,
          ...(model && { models: [...existing.models, model] }),
        };
        return { ...acc, [material]: updated };
      }, {});

      texture = Object.values(merged);
    }

    dispatch(setTexture(texture));
  };
  return (
    <div className="container fabric-gallery-item">
      <div className="row">
        <div className="col-md-6">
          <img src={imagePath} className="active-fabric-image" />
        </div>
        <div className="col-md-6">
          <div className="meta-info">
            <h4>{data.title}</h4>
            <p>{data.content}</p>
            <hr />
          </div>
          {data.brand != undefined ? (
            <div className="brand b-1 mb-3">
              <h5 className="title mb-0">Brand:</h5>
              <p className="mb-0">{brandName}</p>
              {brand.logo !== false && <img src={brand.logo} width={100} />}
              <hr />
            </div>
          ) : (
            ""
          )}
          {data.colors.length > 0 ? (
            <div className="colors mb-3">
              <h5 className="title mb-0">Colors:</h5>
              <div className="d-flex py-2">
                {data.colors.map((color) => {
                  return (
                    <div
                      className="color me-2"
                      title={color.title}
                      style={{ backgroundColor: color.color[0] }}
                    ></div>
                  );
                })}
              </div>
              <hr />
            </div>
          ) : (
            ""
          )}
          {data.patterns != undefined ? (
            <div className="colors mb-3">
              <h5 className="title mb-0">Patterns:</h5>
              <div className="d-flex py-2">{patterns.join(",")}</div>
              <hr />
            </div>
          ) : (
            ""
          )}
          <button className="btn btn-success" onClick={useFabric}>
            Use Fabric
          </button>
        </div>
      </div>
    </div>
  );
}
