import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Button } from "../common";
import { useNavigate } from "react-router-dom";

export default function Herobox() {
  const navigate = useNavigate();
  return (
    <section className="herobox bg-primary-light py-2">
      <Container>
        <Row className="align-items-center">
          <Col md={6}>
            <h2 className="mb-3">LUXURY YOU CAN AFFORD</h2>
            <h4 className="mb-3 lh-base">
              CUSTOM MADE CUSHIONS AND PILLOWS FOR OUTDOOR AND INDOOR LUXURY
              LIVING
            </h4>
            <p className="fs-4 mb-4">
              We are a luxury outdoor lifestyle brand that offers innovation in
              fabrication and functionality in design, for consumers who want
              flexibility throughout the life of their product.
            </p>
            <Button
              variant="transparent-gray"
              className="text-uppercase px-4"
              onClick={() => navigate("/shop")}
            >
              Shop Now
            </Button>
            {/* <Button className="text-uppercase ms-2">Design Your Own</Button> */}
          </Col>
          <Col md={6}>
            <img src="/images/herobox.png" className="mt-4 mt-md-0" />
          </Col>
        </Row>
      </Container>
    </section>
  );
}
