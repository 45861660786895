/**
 * 👉 This component represents Ties Controller gourping
 * two input types (Ties Type and Ties Quantity):
 * ▶️ Show the following options:
 *    None, Velcro, Fabric.
 *
 * ▶️ If the option is set to none, show nothing else.
 * If the option selected i Velcro OR Fabric,
 *
 * 👨‍⚖️ Author: Awais Ahmad
 * 🌐 Author Github: @itsahmadawais
 *
 * 📅 Created: 09 - 01 - 2023
 * 📅 Last Modified: 23 - 01 - 2023
 */
import React, { useEffect, useState } from "react";
import nextId from "react-id-generator";
import { useDispatch, useSelector } from "react-redux";
import { setConfigurations } from "../../../redux/actions";
import { calculateTiesCost } from "../../../utils";
import ShowHide from "./ShowHide";

export default function TiesPicker({ data }) {
  //Ties Options
  const tiesOptions = [
    {
      title: "None",
      value: "none",
    },
    {
      title: "Fabric Ties",
      value: "fabric",
    },
    {
      title: "Velcro Straps",
      value: "velcro",
    },
  ];
  //Select Tie Option State Variable
  const [selectedOption, setSelectedOption] = useState("none");
  const [selectedSubType, setSelectedSubType] = useState(null);

  const configurations = useSelector((state) => state.configurations);

  //Redux State
  const state = useSelector((state) => state);

  //Dispatch
  const dispatch = useDispatch();

  useEffect(() => {
    const setDefaultConfigurations = () => {
      let obj = {
        ties: {
          tiesType: selectedOption,
          option: selectedSubType != null ? selectedSubType.title : "none",
        },
      };
      dispatch(setConfigurations(obj));
      // console.log("🚀 State:", state);
    };
    setDefaultConfigurations();
  }, [selectedOption, selectedSubType]);

  useEffect(() => {
    const updateTiesPrices = () => {
      let tiesPrice = 0;
      if (selectedSubType) {
        if (selectedOption !== "none") {
          tiesPrice = calculateTiesCost({
            ties: selectedSubType.ties,
          });
        }
        let mergedPrices = Object.assign({}, configurations.prices, {
          ties: tiesPrice,
        });
        console.log("Ties Prices:", tiesPrice);
        dispatch(
          setConfigurations({
            prices: mergedPrices,
          })
        );
      }
    };
    updateTiesPrices();
  }, [selectedOption, selectedSubType]);
  //Handler
  const handler = (e) => {
    setSelectedOption(e.target.value);
  };

  const onObjectChange = (option) => {
    // console.log("🚀 Object:", option);
    setSelectedSubType(option);
  };

  return (
    <div className="my-2">
      <h5 className="heading">{data.title}</h5>
      <div className="d-flex options">
        {tiesOptions.map((option, i) => {
          const id = nextId(`ties-picker-${data.uid}`);
          var optionProps = {};
          if (selectedOption === option.value) {
            optionProps["checked"] = "checked";
          }
          return (
            <div key={i} className="option me-2">
              <input
                id={id}
                name={`ties-picker-${data.uid}`}
                type={"radio"}
                className="form-check-input me-3"
                value={option.value}
                onClick={handler}
                {...optionProps}
              />
              <label htmlFor={id}>{option.title}</label>
            </div>
          );
        })}
      </div>
      <div className="my-4">
        {selectedOption != "none" ? (
          <ShowHide showTitle={false} data={data} onChange={onObjectChange} />
        ) : (
          ""
        )}
      </div>
    </div>
  );
}
