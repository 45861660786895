/**
 * 👉 This component renders Cards and is used for Cushion Type Selector
 * and Cushion Shape Selector.
 *
 * 📅 Last Modified: 19 - 01 - 2023
 */
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setCushionShape, setCushionType } from "../../../redux/actions";

export default function InputCushionType({ data }) {
  const [isSelected, setIsSelected] = useState(false);

  const navigate = useNavigate();

  const dispatch = useDispatch();

  //🖱️ OnClick Handler
  const handler = () => {
    setIsSelected(isSelected);
    if (data.type !== undefined && data.type == "cushionType") {
      // console.log('🚀 Component: CushionType, Line: 24', data.shapes);
      dispatch(setCushionType("cushionType", data.value));
      // console.log('✳️ Component Cushion Type - Line 27', cushionTypeState);
      //Navigate to the Shapes Page with Shapes List
      navigate(data.link, {
        state: {
          shapes: data.shapes,
        },
      });
    } else if (data.type !== undefined && data.type == "shape") {
      // console.log('🚀 Component: CushionShape, Line: 38', cushionTypeState);
      dispatch(setCushionShape("cushionShape", data.title));
      // console.log('✳️ Component Cushion Shape - Line 40', cushionTypeState);
      navigate(data.link);
    } else {
      navigate(data.link);
    }
  };

  //🚀 Returns JSX Code
  return (
    <div
      className={`card cushion-type-card h-100 mb-3 ${
        isSelected ? "active" : ""
      }`}
      onClick={handler}
    >
      <img src={data.img} alt={data.title} />
      <div className="card-body text-center">
        <h5 className="card-title fs-6">{data.title}</h5>
      </div>
    </div>
  );
}
