/**
 * 👉 This component renders control for fill type.
 * Default Values for:
 * ▶️ Fill Type is Polyurethane Foam
 * ▶️ Polyster Batting is True
 *
 * 👨‍⚖️ Author: Awais Ahmad
 * 🌐 Author Github: @itsahmadawais
 *
 * 📅 Created: 19 - 01 - 2023
 * 📅 Last Modified: 23 - 01 - 2023
 */
import React, { useEffect, useState } from "react";
import nextId from "react-id-generator";
import { useDispatch, useSelector } from "react-redux";
import { addConfiguration, setConfigurations } from "../../../../redux/actions";
import { FaCircle, FaCheckCircle } from "react-icons/fa";
import CustomModal from "../../CustomModal";
import ModalContent from "./ModalContent";
import CheckButton from "../CheckButton";

export default function FillTypePicker({ data }) {
  //Options State Variable for Fill Type
  const [optionsData, setOptionsData] = useState([
    {
      title: "Polyurethane Foam",
      image: "/images/fill-type/polyurethane-foam.png",
      value: "Polyurethane Foam",
    },
    {
      title: "Bonded Poly Fiber",
      image: "/images/fill-type/bonded-poly-fiber.png",
      value: "Bonded Poly Fiber",
    },
    {
      title: "Reticulated Dri Fast Foam",
      image: "/images/fill-type/reticulated-dri-fast-foam.png",
      value: "Reticulated Dri Fast Foam",
    },
    // {
    //   title: "Polyster Fiber Batting Wrap",
    //   image: "/images/fill-type/polyster-fiber-batting-wrap.png",
    //   value: "Polyster Fiber Batting Wrap",
    // },
  ]);
  //Include Batting State
  const [includeBatting, setIncludeBatting] = useState(true);
  //Include Batting Value Handler
  const battingHandler = () => {
    setIncludeBatting(!includeBatting);
  };
  //Selected Option State Variable
  const [selectedOption, setSelectedOption] = useState("Polyurethane Foam");

  const state = useSelector((state) => state);
  const dispatch = useDispatch();

  //When component is loaded, set the default values to Redux
  useEffect(() => {
    const setDefaultConfigurations = () => {
      let obj = {
        fillType: {
          batting: includeBatting,
          fillType: selectedOption,
        },
      };
      dispatch(setConfigurations(obj));
      // console.log("State:", state);
    };
    setDefaultConfigurations();
  }, [includeBatting, selectedOption]);

  const handler = (e) => {
    if (e.target.checked) {
      setSelectedOption(e.target.value);
      // dispatch(addConfiguration(data.title, e.target.value));
      // console.log('🟢 State Change');
      // console.log('🟡 State:',state);
    }
  };
  return (
    <div className="my-2">
      <div className="d-flex align-items-center">
        <h5 className="heading mb-0">Select Fill Type</h5>
        <CustomModal title="Filling Option Details">
          <ModalContent />
        </CustomModal>
      </div>
      <div className="">
        <CheckButton
          tick={includeBatting}
          title="Include polyster batting (recommended)"
          onClick={battingHandler}
        />
      </div>
      <div className="d-flex options">
        {optionsData.map((option, i) => {
          const id = nextId(data.uid);
          var optionProps = {};
          if (selectedOption == option.value) {
            optionProps["checked"] = "checked";
          }
          return (
            <div key={i} className="option me-2">
              <input
                id={id}
                name={data.uid}
                type={"radio"}
                className="form-check-input me-3"
                value={option.value}
                onChange={(e) => handler(e)}
                {...optionProps}
              />
              <label htmlFor={id}>
                {option.image != undefined && option.img != "" ? (
                  <div className="img-container">
                    <img src={option.image} className="option-img" />
                  </div>
                ) : (
                  ""
                )}
                <div className="img-caption my-2">{option.title}</div>
              </label>
            </div>
          );
        })}
      </div>
      <hr />
    </div>
  );
}

FillTypePicker.defaultProps = {
  title: "",
  options: [],
};
