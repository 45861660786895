import { useTexture } from "@react-three/drei";
import * as THREE from "three";
import React from "react";

export default function MeshTexture({ texture }) {
  // console.log("⭕ Texture:");
  // console.log(texture);
  const colorMap = useTexture(texture, (texture) => {
    texture.wrapS = texture.wrapT = THREE.RepeatWrapping;
    texture.repeat.set(5, 5);
  });
  // console.log(texture);
  return <meshStandardMaterial map={colorMap} />;
}
