import React, { useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import { CSelect, Input } from "../common";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import { checkoutSchema } from "../../utils/schema";
import {
  useElements,
  useStripe,
  PaymentElement,
} from "@stripe/react-stripe-js";
import { useEffect } from "react";
import { toast } from "react-toastify";
import { OrderModel } from "../../service";
import { resetCart } from "../../redux/actions";
import LoginSignupSection from "./LoginSignupSection";
import { useNavigate } from "react-router-dom";
import Loading from "react-fullscreen-loading";
import regionsData from "../../us_states_and_cities.json";

export default function CheckoutBody() {
  const state = useSelector((state) => state.cart);
  const dispatch = useDispatch();
  const stripe = useStripe();
  const elements = useElements();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [orderSuccess, setOrderSuccess] = useState(false);
  const [cityOpts, setCityOpts] = useState([]);
  const [stateOpts, setStateOpts] = useState([]);

  const onPlaceOrder = async (values) => {
    if (!stripe || !elements) {
      // Stripe.js hasn't yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }
    const user = JSON.parse(localStorage.getItem("user"));
    if (!user) {
      toast.error("Login is required!");
      return;
    }

    setLoading(true);

    if (state.total < 1) {
      try {
        let today = new Date();
        let dd = String(today.getDate()).padStart(2, "0");
        let mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
        let yyyy = today.getFullYear();

        today = mm + "-" + dd + "-" + yyyy;
        let orderItem = { ...values };
        orderItem.status = "pending";
        orderItem.date = today;
        orderItem.shipping = Math.round(state.shipping);
        orderItem.salesTax = Math.round(calculateSalesTax());
        orderItem.subtotal = Math.round(state.total);
        orderItem.total = Math.round(
          state.total + state.shipping + calculateSalesTax()
        );
        orderItem.products = state.cartItems.map((item) => {
          return { item: item._id, quantity: item.quantity };
        });
        orderItem.transactionId = "N/A";
        orderItem.userId = user.id;
        let response = await OrderModel.createOrder(orderItem);

        if (response.status == 200) {
          dispatch(resetCart());
          toast.success("Order successful!");
          setOrderSuccess(true);
        }
      } catch (error) {
        console.log(error);
        toast.error("Order unsuccessful!");
      }
    } else {
      const { error, paymentIntent } = await stripe.confirmPayment({
        elements,
        confirmParams: {
          payment_method_data: {
            billing_details: {
              address: {
                line1: values.address,
                line2: "",
                city: values.city,
                state: values.state,
                country: "US",
                postal_code: values.postcode,
              },
            },
          },
          return_url: "http://localhost:3001/",
        },
        redirect: "if_required",
      });

      // This point will only be reached if there is an immediate error when
      // confirming the payment. Otherwise, your customer will be redirected to
      // your `return_url`. For some payment methods like iDEAL, your customer will
      // be redirected to an intermediate site first to authorize the payment, then
      // redirected to the `return_url`.
      if (error) {
        console.log(error.message);
        toast.error("Error occured while payment processing!");
      } else if (paymentIntent && paymentIntent.status === "succeeded") {
        try {
          let today = new Date();
          let dd = String(today.getDate()).padStart(2, "0");
          let mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
          let yyyy = today.getFullYear();

          today = mm + "-" + dd + "-" + yyyy;
          let orderItem = { ...values };
          orderItem.status = "pending";
          orderItem.date = today;
          orderItem.shipping = Math.round(state.shipping);
          orderItem.salesTax = Math.round(calculateSalesTax());
          orderItem.subtotal = Math.round(state.total);
          orderItem.total = Math.round(
            state.total + state.shipping + calculateSalesTax()
          );
          orderItem.products = state.cartItems.map((item) => {
            return { item: item._id, quantity: item.quantity };
          });
          orderItem.transactionId = paymentIntent.id;
          orderItem.userId = user.id;

          let response = await OrderModel.createOrder(orderItem);

          if (response.status == 200) {
            dispatch(resetCart());
            setOrderSuccess(true);
            toast.success("Order successful!");
          }
        } catch (error) {
          console.log(error);
          toast.error("Order unsuccessful!");
        }
      } else {
        console.log(error);
        toast.error("Payment failed");
      }
    }

    setLoading(false);
  };

  useEffect(() => {
    if (!stripe) {
      return;
    }

    const clientSecret = new URLSearchParams(window.location.search).get(
      "payment_intent_client_secret"
    );

    if (!clientSecret) {
      return;
    }

    stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
      switch (paymentIntent.status) {
        case "succeeded":
          toast.success("Payment succeeded!");
          break;
        case "processing":
          toast.info("Your payment is processing.");
          break;
        case "requires_payment_method":
          toast.error("Your payment was not successful, please try again.");
          break;
        default:
          toast.error("Something went wrong.");
          break;
      }
    });
  }, [stripe]);

  useEffect(() => {
    if (loading) {
      document.body.classList.add("overflow-hidden");
    } else {
      document.body.classList.remove("overflow-hidden");
    }
  }, [loading]);

  useEffect(() => {
    let statesArray = [];
    Object.keys(regionsData).forEach(function (key, index) {
      statesArray.push({ label: key, value: key });
    });
    setStateOpts(statesArray);
  }, []);

  const calculateSalesTax = () => {
    let tax = 0;
    if (values.state === "Texas") {
      let subtotal = state.total + state.shipping;
      tax = (subtotal * 8.25) / 100;
    }
    return tax;
  };

  const { values, handleChange, setFieldValue, handleSubmit, errors, touched } =
    useFormik({
      initialValues: {
        firstName: "",
        lastName: "",
        email: "",
        phone: "",
        city: "",
        state: "",
        address: "",
        postcode: "",
      },
      validationSchema: checkoutSchema,
      onSubmit: onPlaceOrder,
    });

  return (
    <Row>
      <Loading loading={loading} background="#f8f9fa80" loaderColor="#000000" />
      {orderSuccess ? (
        <div className="d-flex align-items-center justify-content-center py-4">
          <div>
            <h3>Order Placed Successfully!</h3>
            <div className="d-flex align-items-center justify-content-center gap-3">
              <Button onClick={() => navigate("/shop")}>Shop Again</Button>
              <Button onClick={() => navigate("/")}>Go to Home</Button>
            </div>
          </div>
        </div>
      ) : (
        <>
          <Col lg={8} className="mb-3 mb-lg-0">
            <h5 className="mb-3">Billing Information</h5>
            <Row>
              <Col md={6}>
                <Input
                  name="firstName"
                  placeholder="Enter First Name"
                  className="mb-3"
                  value={values.firstName}
                  onChange={handleChange}
                  error={errors.firstName}
                  touched={touched.firstName}
                  disabled={loading ? "true" : undefined}
                />
              </Col>
              <Col md={6}>
                <Input
                  name="lastName"
                  placeholder="Enter Last Name"
                  className="mb-3"
                  value={values.lastName}
                  onChange={handleChange}
                  error={errors.lastName}
                  touched={touched.lastName}
                  disabled={loading ? "true" : undefined}
                />
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <Input
                  type="email"
                  name="email"
                  placeholder="Enter Email"
                  className="mb-3"
                  value={values.email}
                  onChange={handleChange}
                  error={errors.email}
                  touched={touched.email}
                  disabled={loading ? "true" : undefined}
                />
              </Col>
              <Col md={6}>
                <Input
                  type="tel"
                  name="phone"
                  placeholder="Enter Phone"
                  className="mb-3"
                  value={values.phone}
                  onChange={handleChange}
                  error={errors.phone}
                  touched={touched.phone}
                  disabled={loading ? "true" : undefined}
                />
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <CSelect
                  options={stateOpts}
                  placeholder="Select State"
                  className="mb-3"
                  name="state"
                  onChange={(opt) => {
                    setFieldValue("state", opt.value);
                    let foundValue;
                    for (const key in regionsData) {
                      if (key === opt.value) {
                        foundValue = regionsData[key];
                        break;
                      }
                    }
                    setCityOpts(
                      foundValue.map((item) => {
                        return { label: item, value: item };
                      })
                    );
                    setFieldValue("city", "");
                  }}
                  error={errors.state}
                  touched={touched.state}
                  isDisabled={loading}
                />
              </Col>
              <Col md={6}>
                <CSelect
                  options={cityOpts}
                  placeholder="Select City"
                  className="mb-3"
                  name="city"
                  onChange={(opt) => setFieldValue("city", opt.value)}
                  error={errors.city}
                  touched={touched.city}
                  isDisabled={loading || cityOpts.length === 0}
                />
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <Input
                  type="text"
                  name="address"
                  placeholder="Enter Address"
                  className="mb-3"
                  value={values.address}
                  onChange={handleChange}
                  error={errors.address}
                  touched={touched.address}
                  disabled={loading ? "true" : undefined}
                />
              </Col>
              <Col md={6}>
                <Input
                  type="text"
                  name="postcode"
                  placeholder="Enter Postcode"
                  className="mb-3"
                  value={values.postcode}
                  onChange={handleChange}
                  error={errors.postcode}
                  touched={touched.postcode}
                  disabled={loading ? "true" : undefined}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <LoginSignupSection setLoading={setLoading} />
              </Col>
            </Row>
          </Col>
          <Col lg={4}>
            <div className="order-summary rounded-3 p-3 mb-3">
              <h5>Order Summary</h5>
              {state.cartItems.map((item) => {
                return (
                  <div
                    key={item._id}
                    className="d-flex gap-2 border-bottom pb-2 mb-2"
                  >
                    <img src={item.image} />
                    <div>
                      <p className="fw-normal mb-0">{item.title}</p>
                      <p className="fw-normal mb-0">
                        ${item.perYardPrice.toFixed(2)}
                      </p>
                      <p className="fw-normal mb-0">Qty/YD: {item.quantity}</p>
                    </div>
                  </div>
                );
              })}
              <div className="d-flex justify-content-between mb-2">
                <p className="fw-normal mb-0">Subtotal</p>
                <p className="fw-normal mb-0">${state.total.toFixed(2)}</p>
              </div>
              <div className="d-flex justify-content-between mb-2">
                <p className="fw-normal mb-0">Estimated Shipping</p>
                <p className="fw-normal mb-0">${state.shipping.toFixed(2)}</p>
              </div>
              <div className="d-flex justify-content-between mb-2">
                <p className="fw-normal mb-0">Sales Tax</p>
                <p className="fw-normal mb-0">
                  ${calculateSalesTax().toFixed(2)}
                </p>
              </div>
              <div className="d-flex justify-content-between mb-2">
                <p className="fw-semibold text-dark mb-0">Total</p>
                <p className="fw-semibold text-dark mb-0">
                  $
                  {(state.total + state.shipping + calculateSalesTax()).toFixed(
                    2
                  )}
                </p>
              </div>
            </div>
            {!(state.total < 1) ? (
              <div className="payment-details rounded p-3 mb-3">
                <h5>Payment Details</h5>
                <PaymentElement
                  id="payment-element"
                  options={{
                    layout: "tabs",
                    fields: {
                      billingDetails: {
                        address: "never",
                      },
                    },
                  }}
                />
              </div>
            ) : null}
            <Button
              className="place-order-btn w-100"
              type="submit"
              onClick={handleSubmit}
              disabled={loading || !stripe || !elements}
            >
              {loading ? "loading..." : "Place Order"}
            </Button>
          </Col>
        </>
      )}
    </Row>
  );
}
