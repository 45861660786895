import React from "react";
import { Col, Container, Row } from "react-bootstrap";

export default function GetTheLook() {
  return (
    <section className="get-the-look mt-3">
      <Container>
        <h2 className="fs-2 text-center mb-3 text-uppercase fw-normal">
          Get The Look
        </h2>
        <Row>
          <Col md={6}>
            <img src="/images/combination-1.png" alt="" className="w-100" />
          </Col>
          <Col md={6}>
            <img src="/images/combination-2.png" alt="" className="w-100" />
          </Col>
        </Row>
      </Container>
    </section>
  );
}
