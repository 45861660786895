/**
 * 👉 This utils contains the generic functions that can be used inside
 * any component to perform a specific task or calculations.
 *
 * 👨‍⚖️ Author: Awais Ahmad
 * 🌐 Author Github: @itsahmadawais
 *
 * 📅 Created: 19 - 01 - 2023
 * 📅 Last Modified: 20 - 01 - 2023
 */

import Models from "../components/models/index";
import { INCHES_PER_YARD, MARKUP_RATE, TIES_UNIT_PRICE } from "./constants";

/**
 * Model Generator
 * @param {*} cushion ❓ Cushion Name (in lower case)
 * @param {*} shape  ❓ Cushion Shape (in lower case and without spaces)
 * @param {*} props ❓ Props that we can pass to the Model component to set the materials
 * @returns {*} null | Cushion Component
 */
export function modelSelector(cushion, shape, props = {}) {
  shape = shape.replaceAll(" ", "").toLowerCase();
  // console.log("🔴 Shape: ", shape);
  // console.log("🟢 Cushion: ", cushion);
  const models = {
    seat: {
      squaredcorners: "",
      roundedcorners: {
        render: <Models.Seat.RoundedCorners.Model {...props} />,
        config: Models.Seat.RoundedCorners.Configurations,
      },
      roundedbackcorners: "",
      roundedfrontcorners: "",
      trapezoid: "",
      roundedtop: "",
      round: "",
    },
  };
  // console.log(`✔️ Model Selector Functio - Line 33 - Cushion: ${cushion}, Shape: ${shape}`);
  if (models[cushion] !== undefined) {
    if (models[cushion][shape] !== undefined) {
      // console.log("🚀 Model: ");
      return models[cushion][shape];
    }
  }
  return null;
}
/**
 *
 * @param {*} inputObject
 * @returns
 */
export function uniqueMaterialsProvider(inputObject) {
  if (Object.keys(inputObject).length <= 0) {
    return [];
  }
  var list = [];
  for (const [key, value] of Object.entries(inputObject)) {
    value.objs.forEach((item) => {
      if (!list.includes(item)) {
        list.push(item);
      }
    });
  }
  return list;
}

/**
 * Shape Object Generator
 * @param {*} shapeTitle
 * @returns
 */
function shapeObjectGenerator(shapeTitle) {
  let obj = { link: "/3d-designer" };
  obj["title"] = shapeTitle;
  obj["type"] = "shape";
  switch (shapeTitle) {
    case "Squared Corners":
      obj["img"] = "/images/shapes/SquaredCorners.png";
      break;
    case "Rounded Corners":
      obj["img"] = "/images/shapes/RoundedCorners.png";
      break;
    case "Rounded Back Corners":
      obj["img"] = "/images/shapes/RoundedBackCorners.png";
      break;
    case "Rounded Front Corners":
      obj["img"] = "/images/shapes/RoundedFrontCorners.png";
      break;
    case "Trapezoid":
      obj["img"] = "/images/shapes/Trapezoid.png";
      break;
    case "Rounded Top":
      obj["img"] = "/images/shapes/RoundedTop.png";
      break;
    case "Round":
      obj["img"] = "/images/shapes/Round.png";
      break;
  }
  return obj;
}

/**
 * Shapes List Builder with Image and Link to the 3D Designer
 * @param {*} shapes
 * @returns
 */
export function shapesListBuilder(shapes) {
  var list = [
    "Squared Corners",
    "Rounded Corners",
    "Rounded Back Corners",
    "Rounded Front Corners",
    "Trapezoid",
    "Rounded Top",
    "Round",
  ];
  var filteredList = shapes.filter((item) => {
    return list.includes(item) ? true : false;
  });
  // console.log('🚀 Utils: // Line 77');
  // console.log('🔴 Filtered List:', filteredList);
  filteredList = filteredList.map((item) => {
    return shapeObjectGenerator(item);
  });
  // console.log('🔴 Filtered List:', filteredList);
  return filteredList;
}
/**
 * Calculator
 */
//🚀 Calculate Fabric cost
export function calculateFabricCost({
  cushionStructure,
  dimensions,
  fabricCost,
  isPiping = false,
  isBoxed = false,
}) {
  let cost = 0;
  let ttlYards;
  // If the fabric is applied on Piping
  if (isPiping) {
    //If cushionStructure is center-seam
    if (cushionStructure === "center-seam") {
      ttlYards =
        ((1.75 * dimensions.width * 2 + 1.75 * dimensions.depth * 2) /
          INCHES_PER_YARD) *
        1.25;
    }
    // If cushionStructure is not center-seam
    else {
      ttlYards =
        ((1.75 * dimensions.width * 4 + 1.75 * dimensions.depth * 4) /
          INCHES_PER_YARD) *
        1.25;
    }
    console.log("Fabric Cost:", fabricCost);
    console.log("Piping:", ttlYards);
  }
  //If the fabric is applied on boxed
  else if (isBoxed) {
    ttlYards = dimensions.width * dimensions.thickness * 2;
    ttlYards =
      ttlYards +
      ((dimensions.depth * dimensions.thickness * 2) / INCHES_PER_YARD) * 1.25;
    console.log("Boxed:", ttlYards);
  }
  //If the fabric is applied on the top or bottom
  else {
    if (cushionStructure === "center-seam") {
      ttlYards =
        (((dimensions.width + dimensions.thickness) *
          (dimensions.depth + dimensions.thickness)) /
          INCHES_PER_YARD) *
        1.25;
    } else {
      ttlYards =
        ((dimensions.width * dimensions.depth) / INCHES_PER_YARD) * 1.25;
    }
  }
  cost = ttlYards * fabricCost;
  console.log("Cost:", cost);
  return cost;
}
//🚀 Calculate Fill Cost
export function calculateFillCost({
  dimensions,
  // cushionStructure,
  dacron = 0,
}) {
  let fillCost =
    ((dimensions.width * dimensions.depth * dimensions.thickness) / 144) *
    MARKUP_RATE;
  if (dacron) {
    fillCost += dacron;
  }
  return fillCost ?? 0;
}
//🚀 Calculate Ties
export function calculateTiesCost({ ties = 0 }) {
  return ties * TIES_UNIT_PRICE;
}
//🚀 Calculate F
function calculateTotalLaborCost(basePrice, thickness) {
  let fillLabor = 0;
  let cutAndSewCost = 0;
  switch (parseFloat(thickness)) {
    case 2.0:
      cutAndSewCost = basePrice;
      break;
    case 3.0:
      cutAndSewCost = basePrice + 1.25;
      break;
    case 4.0:
      cutAndSewCost = basePrice + 1.25 * 2;
      break;
    case 5.0:
      cutAndSewCost = basePrice + 1.25 * 3;
      break;
    case 6.0:
      cutAndSewCost = basePrice + 1.25 * 4;
      break;
    case 7.0:
      cutAndSewCost = basePrice + 1.25 * 5;
      break;
    case 8.0:
      cutAndSewCost = basePrice + 1.25 * 6;
      break;
    case 9.0:
      cutAndSewCost = basePrice + 1.25 * 7;
      break;
    case 10.0:
      cutAndSewCost = basePrice + 1.25 * 10;
      break;
    default:
      cutAndSewCost = fillLabor = 0;
  }
  fillLabor = cutAndSewCost / 2;
  return cutAndSewCost + fillLabor;
}
//🚀 Calculate Labor Cost
export function calculateLaborCost(dimensions, dacron = null) {
  let cost = 0;
  let width = dimensions.width;
  let thickness = dimensions.thickness;
  // console.log("Dimensions:", thickness, width);
  let basePrice = 6;
  //If Width > 115
  if (width > 115) {
    basePrice = basePrice + 7;
  } else if (width > 100) {
    basePrice = basePrice + 6;
  } else if (width > 85) {
    basePrice = basePrice + 5;
  } else if (width > 70) {
    basePrice = basePrice + 4;
  } else if (width > 55) {
    basePrice = basePrice + 3;
  } else if (width > 40) {
    basePrice = basePrice + 2;
  } else if (width > 25) {
    basePrice = basePrice + 1;
  }
  cost = calculateTotalLaborCost(basePrice, thickness);
  return cost;
}
//🚀

export const extractFabricImage = (data) => {
  let result = null;
  try {
    let brandName = data.brands[0].title;
    result = `/images/fabric-gallery/${brandName}/${data.title}.jpg`;
  } catch (error) {
    console.log("Error:", error);
  }
  return result;
};
