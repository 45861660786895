import React from "react";
import { MainLayout } from "../layout";
import { Button, Col, Container, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

export default function ComingSoon() {
  const navigate = useNavigate();
  return (
    <MainLayout>
      <section>
        <Container>
          <Row className="align-items-center py-4">
            <Col>
              <h2 className="display-4 fw-semibold">Coming Soon</h2>
              <p className="fs-5">
                We are working on this feature and it will be released soon.
                <br />
                Stay Stuned!
              </p>
              <Button onClick={() => navigate("/")}>Back to Home</Button>
            </Col>
            <Col className="text-center">
              <img
                src="/images/coming-soon-page.png"
                alt="coming-soon"
                className="w-75"
              />
            </Col>
          </Row>
        </Container>
      </section>
    </MainLayout>
  );
}
