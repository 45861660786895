import React, { useState } from "react";
import Colors from "./data/Colors";
export default function ColorFilter({ onColorSelect }) {
  const [activeColor, setActiveColor] = useState(null);
  const onChangeColor = (color) => {
    setActiveColor(color.colorCode);
    onColorSelect(color);
  };
  return (
    <div className="col-md-12">
      <h2>Select Color</h2>
      <div className="d-flex filter_colors">
        {Colors.map((color, index) => {
          return (
            <div
              key={index}
              className={`color-box ${
                activeColor === color.colorCode ? "active" : ""
              }`}
              style={{
                backgroundColor: `${color.colorCode}`,
              }}
              onClick={() => onChangeColor(color)}
              title={color.title}
            >
              {color.colorCode === "multi" && (
                <img className="img-color" src="/images/multi-color.png" />
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
}
