import React from "react";
import { MainLayout } from "../layout";
import { Col, Container, Row } from "react-bootstrap";
import { FaArrowRight, FaTimes } from "react-icons/fa";
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { addQuantity, removeFromCart, removeQuantity } from "../redux/actions";
import { AiOutlineMinus, AiOutlinePlus } from "react-icons/ai";

export default function Cart() {
  const state = useSelector((state) => state.cart);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const onRemoveFromCart = (itemId) => {
    dispatch(removeFromCart(itemId));
  };

  return (
    <MainLayout>
      <Container className="cart py-5">
        <Row>
          <Col>
            <div className="topbar p-3 mb-4">
              <h4 className="pt-1 mb-2 mb-md-0">Cart</h4>
            </div>
          </Col>
        </Row>
        <Row className="cart-items-header d-none d-md-flex">
          <Col md={2}>
            <p className="fw-semibold mb-1">Item</p>
          </Col>
          <Col md={4}>
            <p className="fw-semibold mb-1">Description</p>
          </Col>
          <Col md={2}>
            <p className="fw-semibold mb-1">Unit Price</p>
          </Col>
          <Col md={2}>
            <p className="fw-semibold mb-1">Quantity/Yards</p>
          </Col>
          <Col md={2}>
            <p className="fw-semibold mb-1">Items Total</p>
          </Col>
        </Row>
        {state.cartItems.length ? (
          state.cartItems.map((item) => {
            return (
              <Row
                key={item._id}
                className="cart-item align-items-center py-2 m-2 m-md-0"
              >
                <Col xs={4} md={2} className="mb-3 mb-md-0">
                  <label className="fw-semibold mb-2 d-block d-md-none">
                    Item
                  </label>
                  <img src={item.image} />
                </Col>
                <Col xs={8} md={4}>
                  <p className="fw-normal mb-1">{item.title}</p>
                  <p className="fw-normal">{item.brand.title}</p>
                </Col>
                <Col xs={6} md={2}>
                  <label className="fw-semibold d-block d-md-none">
                    Unit Price
                  </label>
                  <p className="fw-normal">${item.retailPrice.toFixed(2)}</p>
                </Col>
                <Col xs={6} md={2} className="mb-3 mb-md-0">
                  <label className="fw-semibold mb-2 d-block d-md-none">
                    Quantity/Yards
                  </label>
                  <div className="d-flex align-items-center gap-1">
                    <div className="quantity-box bg-light shadow-sm rounded p-2 mb-2">
                      <button
                        className="bg-transparent border-0"
                        onClick={() => dispatch(removeQuantity(item._id))}
                        disabled={item.quantity <= 2}
                      >
                        <AiOutlineMinus />
                      </button>
                      <span className="fw-semibold mx-2">{item.quantity}</span>
                      <button
                        className="bg-transparent border-0"
                        onClick={() => dispatch(addQuantity(item._id))}
                      >
                        <AiOutlinePlus />
                      </button>
                    </div>
                    <button
                      className="bg-transparent border-0 mb-2"
                      onClick={() => onRemoveFromCart(item._id)}
                    >
                      <FaTimes size={16} className="text-danger" />
                    </button>
                  </div>
                </Col>
                <Col md={2}>
                  <label className="fw-semibold d-block d-md-none">
                    Items Total
                  </label>
                  <p className="fw-normal">
                    ${(item.retailPrice * item.quantity).toFixed(2)}
                  </p>
                </Col>
              </Row>
            );
          })
        ) : (
          <div className="text-center py-3">No Items</div>
        )}
        <Row className="mt-3 mt-md-5">
          <Col md={7} lg={8} className="mb-4 mb-md-0">
            <Button variant="secondary" onClick={() => navigate("/shop")}>
              Continue Shopping
            </Button>
          </Col>
          <Col md={5} lg={4}>
            <div className="d-flex justify-content-between border-bottom pb-1 mb-3">
              <p className="fw-normal w-50 mb-0">Subtotal</p>
              <p className="fw-normal mb-0">${state.total.toFixed(2)}</p>
            </div>
            <div className="d-flex justify-content-between border-bottom pb-1 mb-3">
              <p className="fw-normal w-50 mb-0">Estimated Shipping</p>
              <p className="fw-normal mb-0">${state.shipping.toFixed(2)}</p>
            </div>
            <div className="d-flex justify-content-between border-bottom pb-1 mb-3">
              <p className="fw-bold w-50 mb-0">Estimated Total</p>
              <p className="fw-normal mb-0">
                ${(state.total + state.shipping).toFixed(2)}
              </p>
            </div>
            {state.cartItems.length ? (
              <Button
                className="checkout-btn text-uppercase w-100"
                onClick={() => navigate("/checkout")}
              >
                Checkout <FaArrowRight />
              </Button>
            ) : null}
          </Col>
        </Row>
      </Container>
    </MainLayout>
  );
}
