import React, { useState } from "react";
import { Modal } from "react-bootstrap";

import { FaExclamationCircle } from "react-icons/fa";

export default function CustomModal({ title, children }) {
  const [show, setShow] = useState(false);
  const toggleShow = () => {
    setShow(!show);
  };
  return (
    <>
      <button className="btn modal-btn" title={title} onClick={toggleShow}>
        <FaExclamationCircle className="icon" />
      </button>
      <Modal size={"xl"} show={show} onHide={toggleShow}>
        <Modal.Header closeButton>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{children}</Modal.Body>
      </Modal>
    </>
  );
}
