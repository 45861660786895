/**
 * 👉 This file contains the actions for redux operations.
 *
 * 👨‍⚖️ Author: Awais Ahmad
 * 🌐 Author Github: @itsahmadawais
 *
 * 📅 Created: 19 - 01 - 2023
 * 📅 Last Modified: 20 - 01 - 2023
 */

import {
  SET_CUSHION_TYPE,
  SET_CUSHION_SHAPE,
  SET_MODEL,
  ADD_CONFIGURATION,
  SET_HIDDEN_MATERIALS,
  SET_ACTIVE_FABRIC,
  SET_TEXTURE,
  SET_LABOR_COST,
  SET_DIMENSIONS,
  SET_PRICE,
  SET_TIES,
  SET_CUSHION_CONFIGURATION,
  ADD_TO_CART,
  REMOVE_FROM_CART,
  RESET_CART,
  LOGIN_USER,
  LOGOUT_USER,
  ADD_QUANTITY,
  REMOVE_QUANTITY,
} from "../actionTypes/actionTypes";

/**
 * Sets the Cushion Type. i.e Seat, Back, Dinning.
 * @param {*} key
 * @param {*} value
 * @returns
 */
const setCushionType = (key, value) => {
  let payload = {};
  payload[key] = value;
  return {
    type: SET_CUSHION_TYPE,
    payload: payload,
  };
};
/**
 * Sets the Cushion Shape. i.e Rounded Back Corners.
 * @param {*} key
 * @param {*} value
 * @returns
 */
const setCushionShape = (key, value) => {
  let payload = {};
  payload[key] = value;
  return {
    type: SET_CUSHION_SHAPE,
    payload: payload,
  };
};

/**
 * 🚀 Set Model
 * @param {*} model
 * @returns
 */
const setModel = (payload) => {
  return {
    type: SET_MODEL,
    payload: payload,
  };
};

/**
 * 🚀 Set Hidden Materials
 * @param {*} payload
 * @returns
 */
const hideMaterials = (payload) => {
  return {
    type: SET_HIDDEN_MATERIALS,
    payload: payload,
  };
};

//Add Configuration
const addConfiguration = (key, value) => {
  console.log("🟢 Actions: " + JSON.stringify(value));
  return {
    type: ADD_CONFIGURATION,
    key: key,
    value: value,
  };
};

//Set Active Fabric
const setActiveFabric = (show, fabric) => {
  return {
    type: SET_ACTIVE_FABRIC,
    show: show,
    fabric: fabric,
  };
};

//Set Texture
const setTexture = (payload) => {
  return {
    type: SET_TEXTURE,
    payload: payload,
  };
};
/**
 * 🚀 Labor Cost
 */
const setLabor = (payload) => {
  return {
    type: SET_LABOR_COST,
    payload: payload,
  };
};
/**
 * 🚀 Dimensions
 */
const setDimensions = (payload) => {
  return {
    type: SET_DIMENSIONS,
    payload: payload,
  };
};
/**
 * 🚀 Configurations
 */
const setConfigurations = (payload) => {
  return {
    type: SET_CUSHION_CONFIGURATION,
    payload: payload,
  };
};
/**
 * 🚀 Pricing Related
 */
const setPrice = (payload) => {
  return {
    type: SET_PRICE,
    payload: payload,
  };
};
/**
 * 🚀 Set Ties
 */
const setTies = (payload) => {
  return {
    type: SET_TIES,
    payload: payload,
  };
};
/**
 * Add to Cart
 */
const addToCart = (payload) => {
  return {
    type: ADD_TO_CART,
    payload: payload,
  };
};
// Remove from Cart
const removeFromCart = (itemId) => {
  return {
    type: REMOVE_FROM_CART,
    id: itemId,
  };
};
// Reset Cart
const resetCart = () => {
  return {
    type: RESET_CART,
  };
};
// Add Quantity
const addQuantity = (itemId) => {
  return {
    type: ADD_QUANTITY,
    id: itemId,
  };
};
// Remove Quantity
const removeQuantity = (itemId) => {
  return {
    type: REMOVE_QUANTITY,
    id: itemId,
  };
};
// Login User
const loginUser = (payload) => {
  return {
    type: LOGIN_USER,
    payload: payload,
  };
};
// Logout User
const logoutUser = () => {
  return {
    type: LOGOUT_USER,
  };
};
export {
  setCushionType,
  setCushionShape,
  addConfiguration,
  hideMaterials,
  setModel,
  setActiveFabric,
  setTexture,
  setLabor,
  setDimensions,
  setPrice,
  setTies,
  setConfigurations,
  addToCart,
  removeFromCart,
  resetCart,
  addQuantity,
  removeQuantity,
  loginUser,
  logoutUser,
};
