import React from "react";
import Select from "react-select";

export default function CSelect({
  label,
  options,
  className,
  error,
  touched,
  ...props
}) {
  return (
    <div className={className}>
      {label && <label className="form-label">{label}</label>}
      <Select
        options={options}
        {...props}
        styles={{
          control: (styles, { isFocused }) => ({
            ...styles,
            borderColor: isFocused ? "gray" : "lightgray",
            outlineWidth: isFocused ? "0" : "0",
            boxShadow: isFocused ? "none" : "none",
          }),
        }}
      />
      {touched && error && <div className="text-danger">{error}</div>}
    </div>
  );
}
