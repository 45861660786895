/**
 * 👉 This component renders Page for Cushion Selector.
 * The next step after the cushion selector is Shape Selector.
 *
 * 📅 Last Modified: 19 - 01 - 2023
 */
import React from "react";
import { InputCushionType, CushionInfo } from "../../components";
import Header from "../partials/Header";
//Cushion Type Data
import cushions_type from "./data/data";
export default function CushionSelector() {
  return (
    <div className="cushion-selector">
      <div className="container">
        <div className="row">
          <div className="col-md-12 py-5">
            <Header />
            <h1 className="fs-4 mt-5 mb-0">Select Cushion Type</h1>
            <hr />
            <div className="row">
              {cushions_type.map((item, i) => {
                return (
                  <div key={i} className="col-md-2 mb-3">
                    <InputCushionType data={item} />
                  </div>
                );
              })}
            </div>
          </div>
          <div className="col-md-12">
            <CushionInfo />
          </div>
        </div>
      </div>
    </div>
  );
}
