/**
 * It contains two types of Inputs:
 * ▶️ Show the following options: None, Velcro, Fabric.
 * ▶️ If the option is set to none, show nothing else. If the option selected i Velcro OR Fabric,
 */
import React, { useEffect, useState } from "react";
import nextId from "react-id-generator";
import { useDispatch, useSelector } from "react-redux";
import ImageChoices from "./ImageChoices";
import ShowHide from "./ShowHide";
import UNOBox from "./UNOBox";
import FabricGalleryModal from "./fabricgallery";
import {
  calculateFabricCost,
  calculateFillCost,
  extractFabricImage,
} from "../../../utils";
import { setConfigurations } from "../../../redux/actions";
export default function TiesPicker({ data }) {
  const [fabricA, setFabricA] = useState(null);
  const [fabricB, setFabricB] = useState(null);
  const [fabricBox, setFabricBox] = useState(null);
  const [fabricPiping, setFabricPiping] = useState(null);

  const [cushionStructure, setCushionStructure] = useState("boxed");
  const [selectedSupport, setSelectedSupport] = useState("uno");
  const [fabricSupport, setFabricSupport] = useState("duo");

  const model = useSelector((state) => state);
  const userConfigurations = useSelector((state) => state.configurations);

  // console.log("🚀 Data Options:", data);

  const dispatch = useDispatch();

  useEffect(() => {
    const setValidationCheck = () => {
      dispatch(
        setConfigurations({
          validated: false,
        })
      );
      console.log(userConfigurations);
    };
    setValidationCheck();
  }, []);

  useEffect(() => {
    const setFabricsToNull = () => {
      setFabricA(null);
      setFabricB(null);
      setFabricBox(null);
      setFabricPiping(null);
      dispatch(
        setConfigurations({
          validated: false,
        })
      );
    };
    setFabricsToNull();
  }, [selectedSupport]);

  const updateValidationChecktoDone = () => {
    let flag = false;
    if (cushionStructure === "bull-nose") {
      setSelectedSupport("uno");
      if (fabricA === null || fabricPiping === null) {
        //Do nothing
      } else {
        flag = true;
      }
    } else {
      if (cushionStructure === "center-seam") {
        if (selectedSupport === "uno") {
          if (fabricA === null || fabricPiping === null) {
            //Do nothing
          } else {
            flag = true;
          }
        } else {
          if (fabricA === null || fabricB === null || fabricPiping === null) {
            //Do nothing
          } else {
            flag = true;
          }
        }
      } else {
        if (selectedSupport == "uno") {
          if (fabricA === null || fabricPiping === null) {
            //Do nothing
          } else {
            flag = true;
          }
        } else {
          if (
            fabricA === null ||
            fabricB === null ||
            fabricBox === null ||
            fabricPiping === null
          ) {
            //Do nothing
          } else {
            flag = true;
          }
        }
      }
    }
    dispatch(
      setConfigurations({
        validated: flag,
      })
    );
  };

  useEffect(() => {
    const updateRedux = () => {
      let fabricPrices = {};
      let prices = userConfigurations.prices;

      let obj = {
        cushionStructure: {
          cushionStructure: cushionStructure,
          unoDuo: fabricSupport,
          fabrics: {},
        },
      };
      if (fabricA != null) {
        if (cushionStructure === "boxed") {
          obj.cushionStructure.fabrics.sideA = fabricA;
        } else {
          obj.cushionStructure.fabrics.sides = fabricA;
        }
        fabricPrices["sideA"] = calculateFabricCost({
          cushionStructure: cushionStructure,
          dimensions: userConfigurations.dimensions,
          fabricCost: fabricA.price[0],
        });
      }
      if (fabricB != null && cushionStructure === "boxed") {
        obj.cushionStructure.fabrics.sideB = fabricB;
        fabricPrices["sideB"] = calculateFabricCost({
          cushionStructure: cushionStructure,
          dimensions: userConfigurations.dimensions,
          fabricCost: fabricB.price[0],
        });
      }
      if (fabricBox != null) {
        obj.cushionStructure.fabrics.fabricBox = fabricBox;
        fabricPrices["box"] = calculateFabricCost({
          cushionStructure: cushionStructure,
          dimensions: userConfigurations.dimensions,
          fabricCost: fabricBox.price[0],
          isBoxed: true,
        });
      }
      if (fabricPiping != null) {
        obj.cushionStructure.fabrics.fabricPiping = fabricPiping;
        fabricPrices["piping"] = calculateFabricCost({
          cushionStructure: cushionStructure,
          dimensions: userConfigurations.dimensions,
          fabricCost: fabricPiping.price[0],
          isPiping: true,
        });
      }
      updateValidationChecktoDone();

      dispatch(
        setConfigurations({
          cushionStructure: obj,
        })
      );
      let fillCost = calculateFillCost({
        dimensions: userConfigurations.dimensions,
        dacron: prices?.dacron ?? 0,
      });

      dispatch(setConfigurations(obj));
      let mergedPrices = Object.assign({}, prices, {
        fabricPrices: fabricPrices,
        fillCost: fillCost,
      });
      dispatch(
        setConfigurations({
          prices: mergedPrices,
        })
      );
      console.log("🚀 Model:", model);
    };
    updateRedux();
  }, [
    fabricSupport,
    cushionStructure,
    fabricA,
    fabricB,
    fabricBox,
    fabricPiping,
  ]);

  const onCushionStructureChange = (option) => {
    // console.log("🟢 Cushion Structure:", option);
    setCushionStructure(option.value);
    if (option.value != "bull-nose") {
      setFabricSupport("duo");
    } else {
      setFabricSupport("uno");
    }
  };

  const getCenterSeamData = (value) => {
    let tempOption = value;
    let merge = [...tempOption.objs, ...tempOption.objsSideB];
    tempOption.objs = merge;
    // console.log("🔥 Obj:", tempOption);
    return tempOption;
  };

  return (
    <div className="my-2">
      <ShowHide
        data={data.cushionStructure}
        onChange={onCushionStructureChange}
      />
      <UNOBox
        selectedSupport={selectedSupport}
        support={fabricSupport}
        onChange={setSelectedSupport}
      />
      <div className="row">
        <div className="col-md-3">
          <h3 className="h6">Fabric Side A</h3>
          <div className="fabric-box">
            <img
              src={fabricA != null ? extractFabricImage(fabricA) : fabricA}
              className="w-100"
            />
            {fabricA && fabricA?.title && (
              <p className="mb-0">{fabricA.title}</p>
            )}
            {cushionStructure === "bull-nose" ? (
              <FabricGalleryModal
                fabricSetter={setFabricA}
                data={getCenterSeamData(data.options[0])}
              />
            ) : (
              <FabricGalleryModal
                fabricSetter={setFabricA}
                data={data.options[0]}
              />
            )}
          </div>
        </div>
        {fabricSupport === "duo" && selectedSupport === "duo" ? (
          <div className="col-md-3">
            <h3 className="h6">Fabric Side B</h3>
            <div className="fabric-box">
              <img
                src={fabricB != null ? extractFabricImage(fabricB) : fabricB}
                className="w-100"
              />
              {fabricB && fabricB?.title && (
                <p className="mb-0">{fabricB.title}</p>
              )}
              <FabricGalleryModal
                fabricSetter={setFabricB}
                data={data.options[1]}
              />
            </div>
          </div>
        ) : (
          ""
        )}
        <div className="col-md-3">
          {fabricSupport === "duo" &&
          selectedSupport === "duo" &&
          cushionStructure !== "center-seam" ? (
            <div>
              <h3 className="h6">Box/Gusset</h3>
              <div className="fabric-box mb-2">
                <img
                  src={
                    fabricBox != null
                      ? extractFabricImage(fabricBox)
                      : fabricBox
                  }
                  className="w-100 h-half"
                />
                {fabricBox && fabricB?.title && (
                  <p className="mb-0">{fabricBox.title}</p>
                )}
                <FabricGalleryModal
                  fabricSetter={setFabricBox}
                  data={data.options[2]}
                />
              </div>
            </div>
          ) : (
            ""
          )}
          <div>
            <h3 className="h6">Piping</h3>
            <div className="fabric-box">
              <img
                src={
                  fabricPiping != null
                    ? extractFabricImage(fabricPiping)
                    : fabricPiping
                }
                className="w-100 h-half"
              />
              {fabricPiping && fabricPiping?.title && (
                <p className="mb-0">{fabricPiping.title}</p>
              )}
              <FabricGalleryModal
                fabricSetter={setFabricPiping}
                data={data.options[3]}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
