import React from "react";
import { FaRegEye, FaRegHeart } from "react-icons/fa";
import { Link } from "react-router-dom";
import { ASSET_URL } from "../../service";
import { Col, Modal, Row } from "react-bootstrap";
import { useState } from "react";

export default function ProductBox({ data }) {
  const [show, setShow] = useState(false);
  const toggleShow = () => setShow(!show);

  return (
    <>
      <Link to={"/shop/" + data._id}>
        <div className="product-box">
          <div
            className="img-wrapper"
            style={{ backgroundImage: "url('/images/coming-soon.png')" }}
          >
            <img
              src={`${
                data.fabricShopImage
                  ? ASSET_URL + data.fabricShopImage
                  : data.image
                  ? ASSET_URL + data.image
                  : ""
              }`}
              className="rounded-3 mb-2"
            />
            <div className="actions">
              <button
                className="bg-transparent border-0 me-1"
                onClick={(e) => {
                  e.preventDefault();
                  toggleShow();
                }}
              >
                <FaRegEye size={30} color="#fff" />
              </button>
              <button className="bg-transparent border-0">
                <FaRegHeart size={30} color="#fff" />
              </button>
            </div>
          </div>
          <p className="brand text-secondary fw-semibold mb-0">
            {data.brand.title}
          </p>
          <p className="fw-semibold text-uppercase">{data.title}</p>
        </div>
      </Link>
      <Modal show={show} onHide={toggleShow} size="lg" centered>
        <Modal.Header closeButton>
          <Modal.Title>Quick View</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row className="quick-view-modal">
            <Col md={4} lg={5}>
              <div style={{ position: "relative" }}>
                <img
                  src={`${
                    data.fabricShopImage
                      ? ASSET_URL + data.fabricShopImage
                      : data.image
                      ? ASSET_URL + data.image
                      : ""
                  }`}
                  style={{
                    backgroundImage: "url('/images/coming-soon.png')",
                  }}
                  className="product-img rounded mb-2"
                />
              </div>
              <h5 className="fw-semibold text-uppercase">{data.title}</h5>
              <p className="text-primary fs-5 fw-semibold mb-0">
                ${data.retailPrice.toFixed(2)}
              </p>
            </Col>
            <Col md={8} lg={7} className="ps-3">
              <Row>
                <Col md={4} className="fw-semibold mb-2">
                  Brand
                </Col>
                <Col md={8} className="mb-2 text-uppercase">
                  {data.brand.title}
                </Col>
              </Row>
              <Row>
                <Col md={4} className="fw-semibold mb-2">
                  Description
                </Col>
                <Col md={8} className="mb-2 text-uppercase">
                  {data.description}
                </Col>
              </Row>
              <Row>
                <Col md={4} className="fw-semibold mb-2">
                  Colors
                </Col>
                <Col md={8} className="color-strap d-flex gap-1 mb-2">
                  {data.colors.map((item, i) => {
                    return (
                      <div
                        key={i}
                        style={{
                          width: "30px",
                          height: "30px",
                          backgroundColor: item.hexCode,
                          border: "1px solid #00000050",
                        }}
                        className={item.title === "MULTI" ? "multi" : ""}
                      />
                    );
                  })}
                </Col>
              </Row>
              <Row>
                <Col md={4} className="fw-semibold mb-2">
                  Collection
                </Col>
                <Col md={8} className="mb-2 text-uppercase">
                  {data.collections.map((item, i) => {
                    if (
                      i === data.collections.length - 1 ||
                      data.collections.length === 1
                    ) {
                      return item.title;
                    } else {
                      return item.title + ", ";
                    }
                  })}
                </Col>
              </Row>
              <Row>
                <Col md={4} className="fw-semibold mb-2">
                  Patterns
                </Col>
                <Col md={8} className="mb-2 text-uppercase">
                  {data.patterns.map((item, i) => {
                    if (
                      i === data.patterns.length - 1 ||
                      data.patterns.length === 1
                    ) {
                      return item.title;
                    } else {
                      return item.title + ", ";
                    }
                  })}
                </Col>
              </Row>
              <Row>
                <Col md={4} className="fw-semibold mb-2">
                  Fabric Width
                </Col>
                <Col md={8} className="mb-2">
                  {data.fabricWidth + '"'}
                </Col>
              </Row>
              <Row>
                <Col md={4} className="fw-semibold mb-2">
                  Material
                </Col>
                <Col md={8} className="mb-2">
                  {data.fabricContent}
                </Col>
              </Row>
              {!!data.martindaleDoubleRubs && (
                <Row>
                  <Col md={4} className="fw-semibold mb-2">
                    Double Rubs
                  </Col>
                  <Col md={8} className="mb-2">
                    {data.martindaleDoubleRubs.toLocaleString()}
                  </Col>
                </Row>
              )}
              {!!data.repeatHorizontal && (
                <Row>
                  <Col md={4} className="fw-semibold mb-2">
                    Repeat Horizontal
                  </Col>
                  <Col md={8} className="mb-2">
                    {data.repeatHorizontal + '"'}
                  </Col>
                </Row>
              )}
              {!!data.repeatVertical && (
                <Row>
                  <Col md={4} className="fw-semibold mb-2">
                    Repeat Vertical
                  </Col>
                  <Col md={8} className="mb-2">
                    {data.repeatVertical + '"'}
                  </Col>
                </Row>
              )}
              {!!data.ozPerYard && (
                <Row>
                  <Col md={4} className="fw-semibold mb-2">
                    Weight
                  </Col>
                  <Col md={8} className="mb-2">
                    {data.ozPerYard
                      ? data.ozPerYard + " oz/yd"
                      : data.gramsPerMeter
                      ? data.gramsPerMeter + "gm"
                      : "N/A"}
                  </Col>
                </Row>
              )}
              <Row>
                <Col md={4} className="fw-semibold mb-2">
                  Warranty
                </Col>
                <Col md={8} className="mb-2">
                  {data.warrantyOnColorLoss + " years"}
                </Col>
              </Row>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    </>
  );
}
