import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { RiStarFill } from "react-icons/ri";
import Carousel from "nuka-carousel";

export default function Testimonials() {
  const testimonials = [
    {
      id: 1,
      image: "/images/google_g_icon.png",
      name: "Mallory Millsap",
      review:
        "The whole experience with Sam and Cush Living was excellent end to end! Sam helped me customize and design our backyard cushions we’ve dreamt of and the end result is absolutely gorgeous! Highly recommend Cush Living!!!",
    },
    {
      id: 2,
      image: "/images/google_g_icon.png",
      name: "Brooke Dowdy",
      review:
        "I loved working with Samantha on creating the perfect cushions and pillows for my outdoor space. She had creative ideas that really took my backyard to the next level. It was such an easy, fun process! I can't wait to find more things for her to help me with!",
    },
    {
      id: 3,
      image: "/images/google_g_icon.png",
      name: "Rachel Anslover",
      review:
        "I love my adorable floating Mahjong “cushion table” that I ordered from Cush Living. From design help to shipping, they were a pleasure to work with. I can’t wait to use it in the pool all summer!",
    },
    {
      id: 4,
      image: "/images/google_g_icon.png",
      name: "Jess Dudley Prescott",
      review:
        "Cush Living transformed my home and yard. Samantha matched my expectations. She decorated my garden using my favourite materials, colours, and styles. Visionaries are uncommon. Each piece is intended to withstand Texas's unpredictable weather. I love working with Cush Living.",
    },
    {
      id: 5,
      image: "/images/google_g_icon.png",
      name: "Elizabeth Williams",
      review:
        "Finding outdoor pillows and cushions that are colorful and fun is hard when you are also looking for quality. Cush Living gave us both! I can’t wait for the next beautiful weekend to enjoy my new backyard!",
    },
    {
      id: 6,
      image: "/images/google_g_icon.png",
      name: "Monica Kostorowski",
      review:
        "Sam is the best! She’s an absolute pro when it comes to fabric and styling for any design inspiration. Sam helped us hand select all of the best quality materials, combined with texture and color to make a one-of-a-kind patio set. She is even set us up with the identical match in fabrics 2 years later to incorporate our lounge chairs.",
    },
  ];
  const [isMobileWidth, setIsMobileWidth] = useState(false);

  useEffect(() => {
    window.innerWidth < 769 ? setIsMobileWidth(true) : setIsMobileWidth(false);
  }, []);

  return (
    <section className="testimonials py-3">
      <Container>
        <Row>
          <h2 className="fs-2 text-center mb-3 text-uppercase fw-normal">
            Our Lucky Customers
          </h2>
          <Carousel
            slidesToShow={isMobileWidth ? 1 : 3}
            defaultControlsConfig={{
              nextButtonClassName: "d-none",
              prevButtonClassName: "d-none",
            }}
            autoplay
            wrapAround
          >
            {testimonials.map((item) => {
              return (
                <Col key={item.id} className="mb-3 mb-md-0 mx-3 py-5">
                  <div className="testimonial-card text-center border rounded-1 p-4">
                    <img
                      src={item.image}
                      alt="google-icon"
                      className="google-icon"
                    />
                    <p className="fw-semibold mb-1">{item.name}</p>
                    <div className="d-flex justify-content-center">
                      <RiStarFill size={15} />
                      <RiStarFill size={15} />
                      <RiStarFill size={15} />
                      <RiStarFill size={15} />
                      <RiStarFill size={15} />
                    </div>
                    <p className="mt-3">{item.review}</p>
                  </div>
                </Col>
              );
            })}
          </Carousel>
        </Row>
      </Container>
    </section>
  );
}
