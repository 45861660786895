import React from "react";
import { Button, Input } from "../common";
import { useFormik } from "formik";
import { signupSchema } from "../../utils/schema";
import { login, signup } from "../../service";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { loginUser } from "../../redux/actions";

export default function SignupTab({ setLoading }) {
  const dispatch = useDispatch();
  const onSignup = async (values) => {
    setLoading(true);
    try {
      let signupResponse = await signup(values);
      if (signupResponse.data?.success) {
        let response = await login(values);
        let user = response.data;
        //localStorage.setItem("user", JSON.stringify(user));
        dispatch(loginUser(user));
        toast.success("Signup and Login successful!");
        setLoading(false);
      }
    } catch (error) {
      toast.error("Error while signing up!");
      console.log(error);
      setLoading(false);
    }
  };
  const initialValues = {
    firstName: "",
    lastName: "",
    email: "",
    password: "",
  };
  const { values, handleChange, handleBlur, handleSubmit, touched, errors } =
    useFormik({
      initialValues: initialValues,
      validationSchema: signupSchema,
      onSubmit: onSignup,
    });
  return (
    <form onSubmit={handleSubmit}>
      <Input
        className="my-2"
        type="text"
        name="firstName"
        value={values.firstName}
        onChange={handleChange}
        onBlur={handleBlur}
        error={errors.firstName}
        touched={touched.firstName}
        label="First Name"
        placeholder="Enter First Name"
      />
      <Input
        className="my-2"
        type="text"
        name="lastName"
        value={values.lastName}
        onChange={handleChange}
        onBlur={handleBlur}
        error={errors.lastName}
        touched={touched.lastName}
        label="Last Name"
        placeholder="Enter Last Name"
      />
      <Input
        className="my-2"
        type="email"
        name="email"
        value={values.email}
        onChange={handleChange}
        onBlur={handleBlur}
        error={errors.email}
        touched={touched.email}
        label="Enter Email"
        placeholder="cushliving@example.com"
      />
      <Input
        className="my-2"
        type="password"
        name="password"
        value={values.password}
        onChange={handleChange}
        onBlur={handleBlur}
        error={errors.password}
        touched={touched.password}
        label="Enter Password"
        placeholder="Password"
      />
      <div className="mb-2">
        <Button type="submit" className="my-2">
          Signup
        </Button>
      </div>
    </form>
  );
}
