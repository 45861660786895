import React from "react";
import { FaMouse, FaCircleNotch } from "react-icons/fa";
export default function ModalContent() {
  return (
    <div className="row">
      <div className="col-md-6">
        <div className="content">
          <h2 className="fs-5">
            <FaMouse /> Zoom in/Zoom Out
          </h2>
          <p>
            You can zoom in or zoom out the cushion with mouse-wheel if you are
            using mouse or with touchpad if you're using laptop.
          </p>
        </div>
      </div>
      <div className="col-md-6">
        <div className="content">
          <h2 className="fs-5">
            <FaCircleNotch /> Front Side/Back Side
          </h2>
          <p>
            Want to see how the backside, or top side of the cushion will look
            like after applying any fabric item? You can click the cushion and
            drag your mouse around to rotate the cushion.
          </p>
        </div>
      </div>
    </div>
  );
}
