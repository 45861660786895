const cushions_type = [
  {
    title: "Seat OR Ottaman", //Title for the Cushion
    img: "/images/cushion-type/Ottoman.png", //Image Paht for the Cushion
    link: "/shapes/otttaman-seat", // Link for the Shapes
    value: "seat", // Value for the Cushion
    type: "cushionType",
    shapes: [
      // "Squared Corners",
      "Rounded Corners",
      // "Rounded Back Corners",
      // "Rounded Front Corners",
      // "Trapezoid",
      // "Rounded Top",
      // "Round",
    ],
  },
];
export default cushions_type;
