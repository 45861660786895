import React from "react";
import ProductMetaInfo from "./ProductMetaInfo";
import Steps from "./Steps";

export default function Header() {
  return (
    <header className="header">
      <div className="img-logo left">
        <img src="logo-1.png" alt="" className="img" />
      </div>
      <Steps />
      <ProductMetaInfo />
      <div className="img-logo right">
        <img src="logo-1.png" alt="" className="img" />
      </div>
    </header>
  );
}
