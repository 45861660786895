/**
 * 👉 This component renders UI for Show/Hide elements.
 *
 * 👨‍⚖️ Author: Awais Ahmad
 * 🌐 Author Github: @itsahmadawais
 *
 * 📅 Created: 09 - 01 - 2023
 * 📅 Last Modified: 23 - 01 - 2023
 */
import React, { useState } from "react";
import nextId from "react-id-generator";
import { useDispatch, useSelector } from "react-redux";
import {
  addConfiguration,
  hideMaterials,
  setConfigurations,
} from "../../../redux/actions";
import { modelSelector } from "../../../utils";

export default function ShowHide({ showTitle = true, data, onChange }) {
  const [optionsData, setOptionsData] = useState(data.options);
  const [selectedOption, setSelectedOption] = useState(data.defaultValue ?? "");

  const modelState = useSelector((state) => state.model);
  const dispatch = useDispatch();

  const handler = (e, option) => {
    if (e.target.checked) {
      setSelectedOption(option);
      // console.log("🚀 Model Hidden Items:", modelState.hiddenItems);
      // console.log("🔴 All Hidden Items:", data.objs);
      // console.log("Option Hidden Items:", option.objsShow);
      let hiddenItems = modelState.hiddenItems;

      // remove identical strings from hiddenItems and data.objs
      hiddenItems = hiddenItems.filter(
        (item) => !option.objsShow.includes(item)
      );
      let result = data.objs.filter((item) => !option.objsShow.includes(item));

      // merge hiddenItems and data.objs and keep only unique elements
      const merged = [...new Set([...hiddenItems, ...result])];

      // console.log(merged); // output: ["item2", "item3", "item4", "item5"]

      // console.log("⭕ New Hidden Items:", merged);

      dispatch(
        hideMaterials({
          hiddenItems: merged,
        })
      );
      if (onChange) {
        onChange(option);
      } else {
        let obj = {
          [data.title]: option.value,
        };
        dispatch(setConfigurations(obj));
      }
    }
  };
  return (
    <div className="my-2">
      {showTitle && <h5 className="heading">{data.title}</h5>}
      <div className="d-flex options">
        {optionsData.map((option, i) => {
          const id = nextId(data.uid);
          var optionProps = {};
          if (selectedOption === option.value) {
            optionProps["checked"] = "checked";
          }
          return (
            <div key={i} className="option me-2">
              <input
                id={id}
                name={data.uid}
                type={"radio"}
                className="form-check-input me-3"
                value={option.value}
                onClick={(e) => handler(e, option)}
                {...optionProps}
              />
              <label htmlFor={id}>
                {option.image != undefined && option.img != "" ? (
                  <div className="img-container">
                    <img src={option.image} className="option-img" />
                  </div>
                ) : (
                  ""
                )}
                <div className="img-caption my-2">{option.title}</div>
              </label>
            </div>
          );
        })}
      </div>
      <hr />
    </div>
  );
}

ShowHide.defaultProps = {
  title: "",
  options: [],
};
