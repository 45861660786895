import React, { useState } from "react";
import nextId from "react-id-generator";
import { useDispatch, useSelector } from "react-redux";
import { addConfiguration } from "../../../redux/actions";

export default function ImageChoices({ data }) {
  const [optionsData, setOptionsData] = useState(data.options);
  const [selectedOption, setSelectedOption] = useState(data.defaultValue ?? "");

  const state = useSelector((state) => state);
  const dispatch = useDispatch();

  const handler = (e) => {
    // if(e.target.checked){
    //     setSelectedOption(e.target.value);
    //     dispatch(addConfiguration(data.title, e.target.value));
    //     console.log('🟢 State Change');
    //     console.log('🟡 State:',state);
    // }
  };
  return (
    <div className="my-2">
      <h5 className="heading">{data.title}</h5>
      <div className="d-flex options">
        {optionsData.map((option, i) => {
          const id = nextId(data.uid);
          var optionProps = {};
          if (selectedOption == option.value) {
            optionProps["checked"] = "checked";
          }
          return (
            <div key={i} className="option me-2">
              <input
                id={id}
                name={data.uid}
                type={"radio"}
                className="form-check-input me-3"
                value={option.value}
                onChange={(e) => handler(e)}
                {...optionProps}
              />
              <label htmlFor={id}>
                {option.image != undefined && option.img != "" ? (
                  <div className="img-container">
                    <img src={option.image} className="option-img" />
                  </div>
                ) : (
                  ""
                )}
                <div className="img-caption my-2">{option.title}</div>
              </label>
            </div>
          );
        })}
      </div>
      <hr />
    </div>
  );
}

ImageChoices.defaultProps = {
  title: "",
  options: [],
};
