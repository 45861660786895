import { LOGIN_USER, LOGOUT_USER } from "../actionTypes/actionTypes";

const saveToLocalStorage = (state) => {
  try {
    localStorage.setItem("user", JSON.stringify(state));
  } catch (e) {
    console.error(e);
  }
};

const initialState = JSON.parse(localStorage.getItem("user")) ?? {};

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN_USER:
      saveToLocalStorage(action.payload);
      return action.payload;
    case LOGOUT_USER:
      localStorage.removeItem("user");
      return {};
    default:
      return state;
  }
};
export default userReducer;
