import React from "react";
import { Navigate } from "react-router-dom";

export default function Guest({ children }) {
  const user = localStorage.getItem("user");
  if (user) {
    return <Navigate to="/" />;
  }
  return <>{children}</>;
}
