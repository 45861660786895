import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { MdPublishedWithChanges } from "react-icons/md";
//Custom
import FabricGalleryCard from "./FabricGalleryCard";
import FabricGalleryFilter from "./FabricGalleryFilter";
import { setActiveFabric, setTexture } from "../../../../redux/actions";
import { getFabricData } from "../../../../service";
import ActiveFabric from "./ActiveFabric";
import FabricGallery from "./FabricGallery";

export default function FabricGalleryModal({
  imgSetter,
  fabricSetter,
  buttonTitle,
  data,
}) {
  const [fabricGalleryData, setFabricGalleryData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const getFabrics = async () => {
      try {
        let response = await getFabricData();
        let data = [];
        if (response.data) {
          data = response.data.slice(0, 10) ?? [];
        }
        setFabricGalleryData(data);
        console.log("🚀 Fabrics:", response.data, data);
        setIsLoading(false);
      } catch (error) {
        console.log("Error:", error);
      }
    };
    getFabrics();
  }, []);

  const [show, setShow] = useState(false);
  const activeFabric = useSelector((state) => state.fabric);
  const model = useSelector((state) => state.model);
  const dispatch = useDispatch();
  const handleShow = () => {
    setShow(!show);
    // dispatch(setActiveFabric(false, null));
  };
  const goBack = () => {
    dispatch(setActiveFabric(false, null));
  };
  return (
    <>
      <button className="btn btn-sm btn-dark w-100 mt-2" onClick={handleShow}>
        Change Fabric <MdPublishedWithChanges />
      </button>
      <Modal show={show} size="lg" fullscreen={true} onHide={handleShow}>
        <Modal.Header closeButton>
          {activeFabric.show ? (
            <button className="btn btn-primary me-2" onClick={goBack}>
              Go Back
            </button>
          ) : (
            ""
          )}
          <Modal.Title>Fabric Gallery Picker</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {activeFabric.show ? (
            <ActiveFabric
              data={activeFabric.data}
              imgSetter={imgSetter}
              fabricSetter={fabricSetter}
              handleShow={handleShow}
              modelData={data}
            />
          ) : !isLoading ? (
            <FabricGallery fabricData={fabricGalleryData} />
          ) : (
            "Loading..."
          )}
        </Modal.Body>
        {/* <Modal.Footer>
                    <Button variant="secondary" onClick={handleShow}>
                        Close
                    </Button>
                    <Button variant="primary" onClick={handleShow}>
                        Save Changes
                    </Button>
                </Modal.Footer> */}
      </Modal>
    </>
  );
}

FabricGalleryModal.defaultProps = {
  buttonTitle: "Change Fabric",
};
