import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Slide } from "react-slideshow-image";

export default function Gallery() {
  const gallery = [
    "/images/gallery/1.jpg",
    "/images/gallery/2.jpg",
    "/images/gallery/3.jpg",
    "/images/gallery/4.jpg",
    "/images/gallery/5.jpg",
    "/images/gallery/6.jpg",
    "/images/gallery/7.jpg",
    "/images/gallery/8.jpg",
  ];
  return (
    <section className="gallery py-3">
      <Container>
        <Row>
          <Col>
            <h2 className="fs-2 text-center mb-3 text-uppercase fw-normal">
              Inspiration Delivered
            </h2>
            <Slide
              slidesToScroll={1}
              slidesToShow={4}
              indicators={true}
              arrows={false}
            >
              {gallery.map((item, i) => {
                return (
                  <div key={i} className="px-2">
                    <img src={item} />
                  </div>
                );
              })}
            </Slide>
          </Col>
        </Row>
      </Container>
    </section>
  );
}
