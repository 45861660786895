import React from "react";
import { Col, Container, Nav, Row } from "react-bootstrap";
import { NewsletterForm } from "../../components";
import {
  FaEnvelope,
  FaFacebookF,
  FaInstagram,
  FaLinkedinIn,
  FaPhoneAlt,
  FaRegClock,
} from "react-icons/fa";
import MailchimpSubscribe from "react-mailchimp-subscribe";
import { MAILCHIMP_URL } from "../../service";

export default function MainFooter() {
  return (
    <footer className="main-footer">
      <div className="footer-top py-3">
        <Container>
          <Row>
            <Col md={7} className="mb-3 mb-md-0">
              <h2 className="text-primary playfair-font fs-1">
                STAY UP TO DATE
              </h2>
              <p className="fs-4 mb-0">
                Sign up to hear about our latest releases, product updates,
                designer inspiration, promotions & more…
              </p>
            </Col>
            <Col md={5}>
              <MailchimpSubscribe
                url={MAILCHIMP_URL}
                render={({ subscribe, status, message }) => (
                  <NewsletterForm
                    status={status}
                    message={message}
                    onSendForm={(formData) => subscribe(formData)}
                  />
                )}
              />
              <p>
                By subscribing you agree to our{" "}
                <a href="https://www.cush-living.com/terms-of-use/">
                  Terms & Conditions
                </a>{" "}
                and{" "}
                <a href="https://www.cush-living.com/privacy-policy/">
                  Privacy & Cookies Policy.
                </a>
              </p>
            </Col>
          </Row>
        </Container>
      </div>
      <Container className="footer-mid py-4">
        <Row>
          <Col md={4} className="mb-4">
            <h5 className="text-primary text-uppercase mb-3 playfair-font fs-3">
              Contact Us
            </h5>
            <div className="contact-item d-flex align-items-center gap-2 mb-3 text-body">
              <FaRegClock size={26} />
              <p className="mb-0">9:30 AM-6:00 PM MON-FRI</p>
            </div>
            <a
              href="tel:+14697726954"
              className="contact-item d-flex align-items-center gap-2 mb-3 text-body"
            >
              <FaPhoneAlt size={26} />
              <p className="mb-0">(469) 772 6954</p>
            </a>
            <a
              href="mailto:info@cush-living.com"
              className="contact-item d-flex align-items-center gap-2 mb-3 text-body"
            >
              <FaEnvelope size={26} />
              <p className="mb-0">INFO@CUSH-LIVING.COM</p>
            </a>
            <div className="d-flex align-items-center gap-2">
              <a
                href="https://www.facebook.com/cushlivingoutdoor/"
                className="bg-primary rounded-circle px-2 pt-1 pb-2"
              >
                <FaFacebookF size={22} color="#fff" />
              </a>
              <a
                href="https://www.instagram.com/cushliving/?hl=en"
                className="bg-primary rounded-circle px-2 pt-1 pb-2"
              >
                <FaInstagram size={22} color="#fff" />
              </a>
              <a
                href="https://www.linkedin.com/company/cush-living"
                className="bg-primary rounded-circle px-2 pt-1 pb-2"
              >
                <FaLinkedinIn size={22} color="#fff" />
              </a>
            </div>
          </Col>
          <Col md={4} className="mb-3">
            <h5 className="text-primary text-uppercase mb-2 playfair-font fs-3">
              Other Links
            </h5>
            <Nav className="flex-column text-uppercase">
              <Nav.Link
                href="http://cush-living.com/fabric-library/"
                className="pt-0"
              >
                FABRIC LIBRARY
              </Nav.Link>
              <Nav.Link
                href="https://online.flippingbook.com/view/567458799/"
                className="pt-0"
              >
                LOOK BOOK
              </Nav.Link>
              <Nav.Link href="http://cush-living.com/blog/" className="pt-0">
                BLOG
              </Nav.Link>
              <Nav.Link
                href="https://www.cush-living.com/custom-made-cushions-for-outdoor-furniture/"
                className="pt-0"
              >
                SIZING GUIDE
              </Nav.Link>
              <Nav.Link
                href="https://www.cush-living.com/cleaning-instructions/"
                className="pt-0"
              >
                CLEANING INSTRUCTIONS
              </Nav.Link>
              <Nav.Link href="http://cush-living.com/faqs/" className="pt-0">
                FAQS
              </Nav.Link>
              <Nav.Link
                href="http://cush-living.com/privacy-policy/"
                className="pt-0"
              >
                PRIVACY POLICY
              </Nav.Link>
              <Nav.Link
                href="http://cush-living.com/shipping-return-policy/"
                className="pt-0"
              >
                SHIPPING & RETURN POLICY
              </Nav.Link>
              <Nav.Link
                href="http://cush-living.com/terms-of-use/"
                className="pt-0"
              >
                TERMS OF USE
              </Nav.Link>
              <Nav.Link
                href="http://cush-living.com/financing/"
                className="pt-0"
              >
                FINANCING OPTIONS
              </Nav.Link>
            </Nav>
          </Col>
          <Col md={4} className="mb-4">
            <h5 className="text-primary text-uppercase mb-2 playfair-font fs-3">
              Our Company
            </h5>
            <Nav className="flex-column text-uppercase">
              <Nav.Link href="http://cush-living.com/about/" className="pt-0">
                ABOUT CUSH LIVING
              </Nav.Link>
              <Nav.Link href="http://cush-living.com/gallery/" className="pt-0">
                GALLERY
              </Nav.Link>
              <Nav.Link href="http://cush-living.com/contact/" className="pt-0">
                CONTACT US
              </Nav.Link>
            </Nav>
          </Col>
        </Row>
      </Container>
      <div className="footer-bottom">
        <Container>
          <Row className="pt-3 pb-4">
            <Col className="d-flex flex-column flex-md-row align-items-start align-items-md-center gap-3">
              <img src="/images/logo-2.png" alt="Logo" width={180} />
              <p className="mb-0 fw-semibold">
                Copyright {new Date().getFullYear()} © Cush Living All right
                reserved. Powered by 13 Stripes.
              </p>
            </Col>
          </Row>
        </Container>
      </div>
    </footer>
  );
}
