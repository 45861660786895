/**
 * 👉 This component renders page for Three D Modal Rendering.
 *
 * 👨‍⚖️ Author: Awais Ahmad
 * 🌐 Author Github: @itsahmadawais
 *
 * 📅 Created: 19 - 01 - 2023
 * 📅 Last Modified: 20 - 01 - 2023
 */
import React from "react";
import { ThreeDRenderer } from "../components";

export default function PgThreeDRenderer() {
  return <ThreeDRenderer />;
}
