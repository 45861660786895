import {
  ADD_QUANTITY,
  ADD_TO_CART,
  REMOVE_FROM_CART,
  REMOVE_QUANTITY,
  RESET_CART,
} from "../actionTypes/actionTypes";

const SHIPPING_RATE = 4.95;

const saveToLocalStorage = (state) => {
  try {
    localStorage.setItem("cartStore", JSON.stringify(state));
  } catch (e) {
    console.error(e);
  }
};

const emptyState = {
  cartItems: [],
  shipping: 0,
  total: 0,
};
const initialState =
  JSON.parse(localStorage.getItem("cartStore")) ?? emptyState;

const cartReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_TO_CART:
      let newItem = action.payload;
      let existingIndex = state.cartItems.findIndex(
        (item) => item._id === newItem._id
      );
      if (existingIndex != -1) {
        state.cartItems[existingIndex].quantity += newItem.quantity;
        let newState = {
          ...state,
          shipping: state.shipping + SHIPPING_RATE * newItem.quantity,
          total:
            state.total + newItem.retailPrice.toFixed(2) * newItem.quantity,
        };
        saveToLocalStorage(newState);
        return newState;
      } else {
        let newCartItems = [...state.cartItems];
        newCartItems.push(newItem);
        let newState = {
          ...state,
          cartItems: newCartItems,
          shipping: state.shipping + SHIPPING_RATE * newItem.quantity,
          total:
            state.total + newItem.retailPrice.toFixed(2) * newItem.quantity,
        };
        saveToLocalStorage(newState);
        return newState;
      }
    case REMOVE_FROM_CART:
      let itemToRemove = state.cartItems.find((item) => item._id === action.id);
      if (itemToRemove) {
        let newCartItems = state.cartItems.filter(
          (item) => item._id !== action.id
        );
        let newTotal =
          state.total -
          itemToRemove.retailPrice.toFixed(2) * itemToRemove.quantity;
        let newState = {
          ...state,
          cartItems: newCartItems,
          shipping: state.shipping - SHIPPING_RATE * itemToRemove.quantity,
          total: newTotal,
        };
        saveToLocalStorage(newState);
        return newState;
      }
    case RESET_CART:
      localStorage.removeItem("cartStore");
      return emptyState;
    case ADD_QUANTITY:
      let increaseItemIndex = state.cartItems.findIndex(
        (item) => item._id === action.id
      );
      if (increaseItemIndex != -1) {
        state.cartItems[increaseItemIndex].quantity += 1;
        let newState = {
          ...state,
          shipping: state.shipping + SHIPPING_RATE * 1,
          total:
            state.total +
            state.cartItems[increaseItemIndex].retailPrice.toFixed(2) * 1,
        };
        saveToLocalStorage(newState);
        return newState;
      } else {
        return state;
      }
    case REMOVE_QUANTITY:
      let decreaseItemIndex = state.cartItems.findIndex(
        (item) => item._id === action.id
      );
      if (decreaseItemIndex != -1) {
        state.cartItems[decreaseItemIndex].quantity -= 1;
        let newState = {
          ...state,
          shipping: state.shipping - SHIPPING_RATE * 1,
          total:
            state.total -
            state.cartItems[decreaseItemIndex].retailPrice.toFixed(2) * 1,
        };
        saveToLocalStorage(newState);
        return newState;
      } else {
        return state;
      }
    default:
      return state;
  }
};

export default cartReducer;
