import React from "react";
import { MainLayout } from "../layout";
import {
  CTA,
  CushionDesigner,
  Gallery,
  GetTheLook,
  Herobox,
  PillowDesigner,
  Testimonials,
} from "../components";

export default function Home() {
  return (
    <MainLayout>
      <Herobox />
      {/* <CushionDesigner />
      <PillowDesigner /> */}
      <GetTheLook />
      <Testimonials />
      {/* <CTA /> */}
      <Gallery />
    </MainLayout>
  );
}
