import { useFormik } from "formik";
import React, { useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import { Input } from "../components";
import { login } from "../service";
import { FORGOT_PASSWORD_LINK } from "../utils/constants";
import { loginSchema } from "../utils/schema";
import { Button, Col, Container, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { loginUser } from "../redux/actions";

export default function Login() {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const onLogin = async (values) => {
    setLoading(true);
    try {
      let response = await login(values);
      let user = response.data;
      //localStorage.setItem("user", JSON.stringify(user));
      dispatch(loginUser(user));
      navigate("/");
      setLoading(false);
    } catch (error) {
      toast.error("Error while logging in!");
      setLoading(false);
    }
  };
  const initialValues = {
    email: "",
    password: "",
  };
  const { values, handleChange, handleBlur, handleSubmit, touched, errors } =
    useFormik({
      initialValues: initialValues,
      validationSchema: loginSchema,
      onSubmit: onLogin,
    });
  return (
    <section
      className="login"
      style={{ backgroundImage: "url('/images/login-bg.jpg')" }}
    >
      <div className="overlay" />
      <ToastContainer />
      <Container className="vh-100 d-flex align-items-center">
        <Row className="content align-items-center">
          <Col lg={8}>
            <h2>Cush Living</h2>
            <p>
              Welcome to Cush Living, a place for custom made cushoin & pillows
              for outdoor and indoor living.
            </p>
          </Col>
          <Col lg={4}>
            <div className="card">
              <div className="card-body">
                <h3 className="text-center mb-4">Login</h3>
                <form onSubmit={handleSubmit}>
                  <Input
                    className="my-2"
                    type="email"
                    name="email"
                    value={values.email}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={errors.email}
                    touched={touched.email}
                    label="Enter Email"
                    placeholder="cushliving@example.com"
                    disabled={loading ? "true" : undefined}
                  />
                  <Input
                    className="my-2"
                    type="password"
                    name="password"
                    value={values.password}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={errors.password}
                    touched={touched.password}
                    label="Enter Password"
                    placeholder="Password"
                    disabled={loading ? "true" : undefined}
                  />
                  <div className="d-flex justify-content-end my-2 gap-4">
                    <a
                      href={FORGOT_PASSWORD_LINK}
                      style={{ pointerEvents: loading ? "none" : "auto" }}
                    >
                      Forgot password?
                    </a>
                  </div>
                  <div className="mb-2">
                    <Button
                      type="submit"
                      className="my-2 w-100"
                      disabled={loading ? "true" : undefined}
                    >
                      Log in
                    </Button>
                  </div>
                  <div className="text-center">
                    <a
                      href="/signup"
                      style={{ pointerEvents: loading ? "none" : "auto" }}
                    >
                      Dont have an account?
                    </a>
                  </div>
                </form>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
}
