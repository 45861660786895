import React from "react";
import MainHeader from "./partials/MainHeader";
import MainFooter from "./partials/MainFooter";

export default function MainLayout({ children }) {
  return (
    <>
      <MainHeader />
      {children}
      <MainFooter />
    </>
  );
}
