import React, { useEffect, useState } from "react";
import { MainLayout } from "../layout";
import { Col, Container, Row } from "react-bootstrap";
import { Button, CLoader, CSelect, Input, ProductBox } from "../components";
import MultiRangeSlider from "multi-range-slider-react";
import { FabricModel } from "../service";
import ReactPaginate from "react-paginate";
import { useSearchParams } from "react-router-dom";

export default function Shop() {
  const [searchParams, setSearchParams] = useSearchParams();
  const [colors, setColors] = useState([]);
  const [colorOpts, setColorOpts] = useState([]);
  const [brandOpts, setBrandOpts] = useState([]);
  const [patternOpts, setPatternOpts] = useState([]);
  const [selectedBrand, setSelectedBrand] = useState();
  const [selectedColor, setSelectedColor] = useState();
  const [selectedPattern, setSelectedPattern] = useState();
  const [fabrics, setFabrics] = useState([]);
  const [loading, setLoading] = useState(false);
  const [minCost, setMinCost] = useState(
    searchParams.get("minPrice") ? searchParams.get("minPrice") : 0
  );
  const [maxCost, setMaxCost] = useState(
    searchParams.get("maxPrice") ? searchParams.get("maxPrice") : 200
  );
  const [searchTitle, setSearchTitle] = useState(
    searchParams.get("title") ? searchParams.get("title") : ""
  );
  const sortByOpts = [
    { label: "Title", value: "title" },
    { label: "Price", value: "perYardPrice" },
  ];
  const [selectedSort, setSelectedSort] = useState();
  const [pageCount, setPageCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(-1);
  const [perPage, setPerPage] = useState(24);
  const perPageOpts = [
    { label: "24", value: 24 },
    { label: "48", value: 48 },
    { label: "96", value: 96 },
  ];
  const [totalFabrics, setTotalFabrics] = useState(0);

  const fetchBrands = async () => {
    try {
      let res = await FabricModel.getBrands();

      if (res.status === 200) {
        setBrandOpts(
          res.data.data?.map((item) => {
            return { label: item.title, value: item._id };
          })
        );
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  const fetchColors = async () => {
    try {
      let res = await FabricModel.getColors();

      if (res.status == 200) {
        setColors(res.data.data);
        setColorOpts(
          res.data.data?.map((item) => {
            return { label: item.title, value: item._id };
          })
        );
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  const fetchPatterns = async () => {
    try {
      let res = await FabricModel.getPatterns();

      if (res.status == 200) {
        setPatternOpts(
          res.data.data?.map((item) => {
            return { label: item.title, value: item._id };
          })
        );
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  const fetchFabrics = async (page, filters) => {
    setLoading(true);
    try {
      let res = await FabricModel.getFabrics(perPage, page, filters);

      if (res.status == 200) {
        setFabrics(res.data.data);
        setPageCount(res.data.totalPages);
        setCurrentPage(res.data.currentPage - 1);
        setTotalFabrics(res.data.totalFabrics);
        setLoading(false);
      }
    } catch (error) {
      console.log(error.message);
      setLoading(false);
    }
  };

  const onPageClick = (e) => {
    let filters = {
      brand: searchParams.get("brand") ? searchParams.get("brand") : null,
      color: searchParams.get("color") ? searchParams.get("color") : null,
      pattern: searchParams.get("pattern") ? searchParams.get("pattern") : null,
      minPrice: searchParams.get("minPrice"),
      maxPrice: searchParams.get("maxPrice"),
      title: searchParams.get("title") ? searchParams.get("title") : "",
      sortBy: searchParams.get("sortBy") ? searchParams.get("sortBy") : null,
    };
    fetchFabrics(e.selected + 1, filters);
    window.scrollTo(0, 0);
  };

  const onFilterColor = (item) => {
    setSelectedColor(colorOpts.find((opt) => opt.value === item._id));
    searchParams.set("color", item._id);
    setSearchParams(searchParams);
    window.location.reload();
  };

  const onFilterReset = () => {
    setSelectedBrand(null);
    setSelectedColor(null);
    setSelectedPattern(null);
    setMinCost(0);
    setMaxCost(200);
    setSearchTitle("");
    setSelectedSort(null);
    setSearchParams({});
    window.location.reload();
  };

  useEffect(() => {
    let filters = {
      brand: searchParams.get("brand") ? searchParams.get("brand") : null,
      color: searchParams.get("color") ? searchParams.get("color") : null,
      pattern: searchParams.get("pattern") ? searchParams.get("pattern") : null,
      minPrice: searchParams.get("minPrice"),
      maxPrice: searchParams.get("maxPrice"),
      title: searchParams.get("title") ? searchParams.get("title") : "",
      sortBy: searchParams.get("sortBy") ? searchParams.get("sortBy") : null,
    };
    fetchFabrics(1, filters);
  }, [perPage]);

  useEffect(() => {
    fetchBrands();
    fetchColors();
    fetchPatterns();
    let filters = {
      brand: searchParams.get("brand") ? searchParams.get("brand") : null,
      color: searchParams.get("color") ? searchParams.get("color") : null,
      pattern: searchParams.get("pattern") ? searchParams.get("pattern") : null,
      minPrice: searchParams.get("minPrice"),
      maxPrice: searchParams.get("maxPrice"),
      title: searchParams.get("title") ? searchParams.get("title") : "",
      sortBy: searchParams.get("sortBy") ? searchParams.get("sortBy") : null,
    };
    fetchFabrics(1, filters);
  }, []);

  return (
    <MainLayout>
      <Container className="shop py-5">
        <Row>
          <Col md={4} lg={3} className="mb-3 mb-md-0">
            <form>
              <div className="filters p-3 rounded-3">
                <h4>Search Filter</h4>
                {!loading && (
                  <>
                  <Input
                      label="Title"
                      placeholder={
                        searchParams.get("title")
                          ? searchParams.get("title")
                          : "Enter term"
                      }
                      value={searchTitle}
                      onChange={(e) => setSearchTitle(e.target.value)}
                      className="mb-3"
                      disabled={loading}
                    />
                    <CSelect
                      label="Brand"
                      options={brandOpts}
                      defaultValue={brandOpts.find(
                        (opt) => opt.value === searchParams.get("brand")
                      )}
                      value={selectedBrand}
                      className="mb-3"
                      isDisabled={loading}
                      onChange={(opt) => setSelectedBrand(opt)}
                    />
                    <input
                      type="hidden"
                      name="brand"
                      value={selectedBrand?.value}
                      defaultValue={searchParams.get("brand")}
                    />
                    <CSelect
                      label="Pattern"
                      options={patternOpts}
                      defaultValue={patternOpts.find(
                        (opt) => opt.value === searchParams.get("pattern")
                      )}
                      value={selectedPattern}
                      onChange={(opt) => setSelectedPattern(opt)}
                      className="mb-3"
                      isDisabled={loading}
                    />
                    <input
                      type="hidden"
                      name="pattern"
                      value={selectedPattern?.value}
                      defaultValue={searchParams.get("pattern")}
                    />
                    <input
                      type="hidden"
                      name="title"
                      value={searchTitle}
                      defaultValue={searchParams.get("title")}
                    />
                    <CSelect
                      label="Sort By"
                      options={sortByOpts}
                      defaultValue={sortByOpts.find(
                        (opt) => opt.value === searchParams.get("sortBy")
                      )}
                      value={selectedSort}
                      onChange={(opt) => setSelectedSort(opt)}
                      className="mb-3"
                      isDisabled={loading}
                    />
                    <input
                      type="hidden"
                      name="sortBy"
                      value={selectedSort?.value}
                      defaultValue={searchParams.get("sortBy")}
                    />
                    <div className="mb-4">
                      <label className="form-label">Per Yard Price ($)</label>
                      <MultiRangeSlider
                        min={0}
                        max={200}
                        step={1}
                        ruler={false}
                        barLeftColor="#E4E4E4"
                        barRightColor="#E4E4E4"
                        barInnerColor="#cba554"
                        thumbLeftColor="#cba554"
                        thumbRightColor="#cba554"
                        style={{
                          boxShadow: "none",
                          border: "none",
                          padding: "10px 0",
                        }}
                        minValue={minCost}
                        maxValue={maxCost}
                        onInput={(e) => {
                          setMinCost(e.minValue);
                          setMaxCost(e.maxValue);
                        }}
                        minCaption={minCost}
                        maxCaption={maxCost}
                        labels={["$0", "$200"]}
                      />
                      <input
                        type="hidden"
                        name="minPrice"
                        value={minCost}
                        defaultValue={searchParams.get("minPrice")}
                      />
                      <input
                        type="hidden"
                        name="maxPrice"
                        value={maxCost}
                        defaultValue={searchParams.get("maxPrice")}
                      />
                      <input
                        type="hidden"
                        name="color"
                        value={selectedColor?.value}
                        defaultValue={searchParams.get("color")}
                      />
                    </div>
                    <div className="d-flex justify-content-end gap-2">
                      <Button
                        variant="secondary"
                        disabled={loading ? "disabled" : undefined}
                        onClick={onFilterReset}
                        type="button"
                      >
                        Reset
                      </Button>
                      <Button
                        variant="primary"
                        disbaled={loading ? "disabled" : undefined}
                        type="submit"
                      >
                        Search
                      </Button>
                    </div>
                  </>
                )}
              </div>
            </form>
          </Col>
          <Col md={8} lg={9}>
            {!loading && (
              <div className="color-strap d-flex flex-wrap mb-3">
                {colors.map((item, i) => {
                  return (
                    <div
                      key={i}
                      style={
                        searchParams.get("color") === item._id
                          ? {
                              backgroundColor: item.hexCode,
                              width: "35px",
                              height: "35px",
                              cursor: "pointer",
                              border: "3px dashed #818878",
                            }
                          : {
                              backgroundColor: item.hexCode,
                              width: "35px",
                              height: "35px",
                              cursor: "pointer",
                              border: "1px solid #00000010",
                            }
                      }
                      className={item.title === "MULTI" ? "multi" : ""}
                      onClick={() => onFilterColor(item)}
                    />
                  );
                })}
              </div>
            )}
            <Row>
              {!loading && (
                <div className="d-flex align-items-center justify-content-between">
                  <p>Total Fabrics: {totalFabrics}</p>
                  <div className="d-flex align-items-center gap-2">
                    <p>Show Per Page</p>
                    <CSelect
                      options={perPageOpts}
                      value={perPageOpts.find((opt) => opt.value === perPage)}
                      className="mb-3"
                      isDisabled={loading}
                      onChange={(opt) => setPerPage(opt.value)}
                    />
                  </div>
                </div>
              )}
              {loading ? (
                <div className="d-flex align-items-center justify-content-center">
                  <CLoader />
                </div>
              ) : fabrics.length ? (
                fabrics.map((item) => {
                  return (
                    <Col key={item._id} md={4} lg={3}>
                      <ProductBox data={item} />
                    </Col>
                  );
                })
              ) : (
                <div className="d-flex align-items-center justify-content-center">
                  <p className="fs-5 fw-semibold">No Fabrics</p>
                </div>
              )}
              <div
                className={`d-flex justify-content-center mt-3 ${
                  loading ? "d-none" : ""
                }`}
              >
                <ReactPaginate
                  previousLabel={"prev"}
                  nextLabel={"next"}
                  breakLabel={"..."}
                  pageCount={pageCount}
                  forcePage={currentPage}
                  marginPagesDisplayed={2}
                  pageRangeDisplayed={5}
                  onPageChange={onPageClick}
                  containerClassName={"pagination"}
                  activeClassName={"active"}
                  disabledClassName={"disabled"}
                />
              </div>
            </Row>
          </Col>
        </Row>
      </Container>
    </MainLayout>
  );
}
