/**
 * 👉 This file contains the list of action types for redux operations.
 *
 * 👨‍⚖️ Author: Awais Ahmad
 * 🌐 Author Github: @itsahmadawais
 *
 * 📅 Created: 19 - 01 - 2023
 * 📅 Last Modified: 20 - 01 - 2023
 */

export const ADD_CONFIGURATION = "ADD_CONFIGURATION";
export const SET_TEXTURE = "SET_TEXTURE";
export const SET_ACTIVE_FABRIC = "SET_ACTIVE_FABRIC";
export const SET_DIMENSIONS = "SET_DIMENSIONS";
export const FILTER_FABRIC = "FILTER_FABRIC";
export const UPDATE_PRICE = "UPDATE_PRICE";

/**
 * 🚀 Configurations Related ~ Configurations that
 * user will do for the product designing!
 */

/**
 * 🚀 Model Related
 */
export const SET_MODEL = "SET_MODEL";
export const SET_HIDDEN_MATERIALS = "HIDE_MATERIALS";

/**
 * 🚀 Configurations Related
 */
//Sets Cushion Type
export const SET_CUSHION_TYPE = "SET_CUSHION_TYPE";
//Sets Cushion Shape
export const SET_CUSHION_SHAPE = "SET_CUSHION_SHAPE";
//Sets Cushion Configurations
export const SET_CUSHION_CONFIGURATION = "SET_CUSHION_CONFIGURATION";

/**
 * 🚀 Pricing Related
 */
export const SET_LABOR_COST = "SET_LABOR_COST";
export const SET_PRICE = "SET_PRICE";

/**
 * 🚀 Ties Related
 */
export const SET_TIES = "SET_TIES";

/**
 * Cart Related
 */
export const ADD_TO_CART = "ADD_TO_CART";
export const REMOVE_FROM_CART = "REMOVE_FROM_CART";
export const RESET_CART = "RESET_CART";
export const ADD_QUANTITY = "ADD_QUANTITY";
export const REMOVE_QUANTITY = "REMOVE_QUANTITY";

/**
 * User Related
 */
export const LOGIN_USER = "LOGIN_USER";
export const LOGOUT_USER = "LOGOUT_USER";
