import React from "react";

export default function ModalContent() {
  return (
    <div className="content">
      Measurements do not include the thickness of welting, which is
      approximately 1/4" (.25). Welting can add up to 1/2" (.5) to the overall
      dimensions of each cushion. *If cushions need to fit in an exact, confined
      space, you may need to subtract 1/2" from your width, depth, or height
      before ordering.
    </div>
  );
}
