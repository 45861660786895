import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { setActiveFabric } from "../../../../redux/actions";

export default function FabricGalleryCard({ data }) {
  const dispatch = useDispatch();
  const brandName = data.brands[0].title;
  const imagePath = `/images/fabric-gallery/${brandName}/${data.title}.jpg`;

  const handler = () => {
    dispatch(setActiveFabric(true, data));
    console.log(data);
  };
  return (
    <div className="fabric-card card" onClick={handler}>
      <div className="card-body">
        <img src={imagePath} className="fabric-img" />
        <div className="overlay-content">
          <p className="label mb-0">{data.title}</p>
        </div>
      </div>
    </div>
  );
}
