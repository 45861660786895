export default [
    {
        q: `WHATS THE DIFFERENCE IN A DINING CUSHION AND A SEAT CUSHION?`,
        ans: `Dining cushions are smaller in size. Width/depth options from 15”-21” with a thickness range from 2”-4”
        Seat Cushions are larger in size. Width/Depth options from 15”-120” with a thickness range from 4”-10”`
    },
    {
        q: `COUNTLESS DESIGN COMBINATIONS FOR DINING CUSHIONS & SEAT CUSHIONS.`,
        ans: `Custom dining cushions and seat cushions are available in multiple shapes and structures to accommodate any design style, from traditional to modern. Design
        your very own replacement cushions for your patio furniture, dining chairs and more. Choose from thousands of performance fabrics to reflect your personal
        style and get creative using our design tool, that allows you to select up to 4 fabrics per cushion... making it reversible, transitional and one of a kind !!! Add ties
        and detail options to complete the finishing touches.`
    },
    {
        q: `PRODUCT SPECIFICATIONS & AVAILABILITY:`,
        ans: `All of the cushions that are offered on the CushLiving.com web site are manufactured per order, based on customer specifications and are available for ordering
        in any of the performance fabrics listed in our fabric gallery. While we try to update our fabric collection on a regular basis, we are subject to the notifications of
        fabric availability provided by our suppliers . As a result we are unable to guarantee that all of the fabrics appearing on the CushLiving.com web site will be available
        from the manufacturer. If your order contains an item with a particular fabric that has been discontinued or otherwise is no longer available but is still appearing on
        our site, you will be notified as soon as possible by phone or email.`
    },
    {
        q: `YOU OFFER TRIMMING ON YOUR CUSHIONS?`,
        ans: <p>Yes. Welting/Piping is optional on all custom made cushions. Welting is a type of trimming that is sewn into the seams of the cushion in which the seam cannot be
        seen and the cushion or pillow is given an appearance of a piping or tubing instead. Welting can be made in either the same fabric or contrasting fabric colors. This
        provides a crisper look to the cushion while providing enhanced stitching durability.
        Cushions made with a waterfall edge construction have a single welt option as there is only one seam. Cushions made in a boxed edge construction have double
        welt options for the top and bottom seams of the pad. <br/>
        <b>Disclaimer:</b> Adding single or double welt trimming to your cushion may add up to 1⁄4′′ to the dimensions on all sides.</p>
    },
    {
        q: `DO YOUR CUSHIONS INCLUDE ZIPPERS?`,
        ans: <p>Zippers are located at the back width on all of our cushions except waterfall edge seat pads that are completely round or have fully rounded backs, hinged chair
        cushions, hinged chaise cushions.</p>
    }
]