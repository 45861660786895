import React, { useEffect, useState } from "react";
import { MainLayout } from "../layout";
import { Col, Container, Row } from "react-bootstrap";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import { CheckoutBody } from "../components";
import { createPaymentIntent } from "../service";
import { useSelector } from "react-redux";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISH_KEY);

export default function Checkout() {
  const state = useSelector((state) => state.cart);
  const [clientSecret, setClientSecret] = useState("");

  useEffect(() => {
    if (!(state.total < 1)) {
      // Create PaymentIntent as soon as the page loads
      const fetchIntent = async () => {
        try {
          let response = await createPaymentIntent({ items: state.cartItems });
          if (response.status == 200) {
            setClientSecret(response.data.clientSecret);
          }
        } catch (error) {
          console.log(error);
        }
      };
      fetchIntent();
    }
  }, []);

  const appearance = {
    theme: "stripe",
  };
  const options = {
    clientSecret,
    appearance,
  };

  return (
    <MainLayout>
      <Container className="checkout py-5">
        <Row>
          <Col>
            <div className="topbar p-3 mb-4">
              <h4 className="pt-1 mb-2 mb-md-0">Checkout</h4>
            </div>
          </Col>
        </Row>
        {clientSecret || state.total < 1 ? (
          <Elements options={options} stripe={stripePromise}>
            <CheckoutBody />
          </Elements>
        ) : null}
      </Container>
    </MainLayout>
  );
}
