import React, { useEffect, useState } from "react";
import { MainLayout } from "../layout";
import { Col, Container, Row } from "react-bootstrap";
import DataTable from "react-data-table-component";
import { ASSET_URL, OrderModel } from "../service";
import { CLoader } from "../components";

export default function Dashboard() {
  const statusOpts = [
    { label: "Pending", value: "pending" },
    { label: "Canceled", value: "canceled" },
    { label: "Awaiting Payment", value: "awaiting-payment" },
    { label: "In Progress", value: "in-progress" },
    { label: "Shipped", value: "shipped" },
    { label: "Completed", value: "completed" },
  ];
  const columns = [
    {
      name: "Order",
      selector: (row) => "#" + row._id,
    },
    {
      name: "Date",
      selector: (row) => row.date,
    },
    {
      name: "Status",
      selector: (row) =>
        statusOpts.find((item) => item.value === row.status)?.label,
    },
    {
      name: "Total",
      selector: (row) => "$" + row.total,
    },
  ];
  // const orders = [
  //   {
  //     id: "231",
  //     date: "23-05-2023",
  //     status: "Processing",
  //     products: [
  //       {
  //         id: 1,
  //         image: "/images/product.png",
  //         title: "Accord II - Crimson",
  //         description: "Accord II - Crimson",
  //         brand: {
  //           name: "Subrella",
  //           image: "/images/brand-img.png",
  //         },
  //         colors: ["red", "honey", "yellow", "green"],
  //         patterns: ["Geometric"],
  //         collection: "Accord",
  //         fabricWidth: "54",
  //         material: "100% Subrella Acrylic",
  //         doubleRubs: "15000",
  //         weight: "11.7 os/sq yd",
  //         warranty: "5 Years",
  //         perYardPrice: "59.95",
  //         quantity: "4",
  //       },
  //       {
  //         id: 2,
  //         image: "/images/product.png",
  //         title: "Accord II - Crimson",
  //         description: "Accord II - Crimson",
  //         brand: {
  //           name: "Subrella",
  //           image: "/images/brand-img.png",
  //         },
  //         colors: ["red", "honey", "yellow", "green"],
  //         patterns: ["Geometric"],
  //         collection: "Accord",
  //         fabricWidth: "54",
  //         material: "100% Subrella Acrylic",
  //         doubleRubs: "15000",
  //         weight: "11.7 os/sq yd",
  //         warranty: "5 Years",
  //         perYardPrice: "59.95",
  //         quantity: "4",
  //       },
  //     ],
  //     subtotal: "786.70",
  //     shipping: "82.00",
  //     total: "860.70",
  //   },
  //   {
  //     id: "640",
  //     date: "23-05-2023",
  //     status: "Completed",
  //     products: [
  //       {
  //         id: 1,
  //         image: "/images/product.png",
  //         title: "Accord II - Crimson",
  //         description: "Accord II - Crimson",
  //         brand: {
  //           name: "Subrella",
  //           image: "/images/brand-img.png",
  //         },
  //         colors: ["red", "honey", "yellow", "green"],
  //         patterns: ["Geometric"],
  //         collection: "Accord",
  //         fabricWidth: "54",
  //         material: "100% Subrella Acrylic",
  //         doubleRubs: "15000",
  //         weight: "11.7 os/sq yd",
  //         warranty: "5 Years",
  //         perYardPrice: "59.95",
  //         quantity: "4",
  //       },
  //     ],
  //     subtotal: "786.70",
  //     shipping: "82.00",
  //     total: "860.70",
  //   },
  //   {
  //     id: "345",
  //     date: "23-05-2023",
  //     status: "Completed",
  //     products: [
  //       {
  //         id: 1,
  //         image: "/images/product.png",
  //         title: "Accord II - Crimson",
  //         description: "Accord II - Crimson",
  //         brand: {
  //           name: "Subrella",
  //           image: "/images/brand-img.png",
  //         },
  //         colors: ["red", "honey", "yellow", "green"],
  //         patterns: ["Geometric"],
  //         collection: "Accord",
  //         fabricWidth: "54",
  //         material: "100% Subrella Acrylic",
  //         doubleRubs: "15000",
  //         weight: "11.7 os/sq yd",
  //         warranty: "5 Years",
  //         perYardPrice: "59.95",
  //         quantity: "4",
  //       },
  //     ],
  //     subtotal: "786.70",
  //     shipping: "82.00",
  //     total: "860.70",
  //   },
  // ];

  const [orders, setOrders] = useState([]);
  const [pending, setPending] = useState(true);
  const user = JSON.parse(localStorage.getItem("user"));

  useEffect(() => {
    if (user?.id) {
      const fetchOrders = async () => {
        try {
          let response = await OrderModel.getUserOrders(user.id);

          if (response.status === 200) {
            setOrders(response.data.data);
            setPending(false);
          }
        } catch (error) {
          console.log(error);
          setPending(false);
        }
      };
      fetchOrders();
    }
  }, []);

  const expandableContent = ({ data }) => {
    return (
      <Row className="p-4">
        <Col md={6}>
          <h5>Items</h5>
          <div className="border rounded p-2">
            {data.products.map((item) => {
              return (
                <div
                  key={item.item._id}
                  className="d-flex gap-2 border-bottom pb-2 mb-2"
                >
                  <img src={ASSET_URL + item.item.image} />
                  <div>
                    <p className="fw-normal mb-0">{item.item.title}</p>
                    <p className="fw-normal mb-0">
                      ${item.item.perYardPrice.toFixed(2)}
                    </p>
                    <p className="fw-normal mb-0">Qty/YD: {item.quantity}</p>
                  </div>
                </div>
              );
            })}
          </div>
        </Col>
        <Col md={6}>
          <h5>Cost</h5>
          <div className="border rounded p-2">
            <div className="d-flex justify-content-between mb-2">
              <p className="fw-normal mb-0">Subtotal</p>
              <p className="fw-normal mb-0">${data.subtotal}</p>
            </div>
            <div className="d-flex justify-content-between mb-2">
              <p className="fw-normal mb-0">Estimated Shipping</p>
              <p className="fw-normal mb-0">${data.shipping}</p>
            </div>
            <div className="d-flex justify-content-between mb-2">
              <p className="fw-normal mb-0">Sales Tax</p>
              <p className="fw-normal mb-0">${data.salesTax}</p>
            </div>
            <div className="d-flex justify-content-between mb-2">
              <p className="fw-semibold text-dark mb-0">Total</p>
              <p className="fw-semibold text-dark mb-0">${data.total}</p>
            </div>
          </div>
        </Col>
      </Row>
    );
  };

  return (
    <MainLayout>
      <Container className="dashboard py-5">
        <Row>
          <Col>
            <div className="topbar p-3 mb-4">
              <h4 className="pt-1 mb-2 mb-md-0">Dashboard</h4>
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <DataTable
              columns={columns}
              data={orders}
              expandableRows
              expandableRowsComponent={expandableContent}
              progressPending={pending}
              progressComponent={<CLoader />}
              pagination
              responsive
            />
          </Col>
        </Row>
      </Container>
    </MainLayout>
  );
}
