import React from "react";
import { Tab, Tabs } from "react-bootstrap";
import LoginTab from "./LoginTab";
import SignupTab from "./SignupTab";

export default function LoginSignupSection({ setLoading }) {
  const user = JSON.parse(localStorage.getItem("user"));
  return !user ? (
    <div className="border-top py-3">
      <Tabs defaultActiveKey="login" className="checkout-tabs">
        <Tab eventKey="login" title="Login">
          <LoginTab setLoading={setLoading} />
        </Tab>
        <Tab eventKey="signup" title="Signup">
          <SignupTab setLoading={setLoading} />
        </Tab>
      </Tabs>
      <p>Please Login OR Signup first to place order.</p>
    </div>
  ) : null;
}
