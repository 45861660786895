import React from "react";
import { ClipLoader } from "react-spinners";

export default function Spinner() {
  return (
    <ClipLoader
      color={"white"}
      loading={true}
      size={20}
      aria-label="Loading Spinner"
      data-testid="loader"
    />
  );
}
