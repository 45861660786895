import React, { useState } from "react";

export default function QuantitySelector({
  quantity,
  isSubmitting,
  setQuantity,
}) {
  const quantityIncrement = () => {
    if (!isSubmitting) {
      setQuantity(quantity + 1);
    }
  };
  const quantityDecrement = () => {
    if (quantity > 1) {
      if (!isSubmitting) {
        setQuantity(quantity - 1);
      }
    }
  };
  return (
    <div className="quantity-selector">
      <div className="d-flex input-box align-items-center">
        <button className="btn btn-dark item" onClick={quantityDecrement}>
          -
        </button>
        <p className="input mb-0 item">{quantity}</p>
        <button className="btn btn-dark item" onClick={quantityIncrement}>
          +
        </button>
      </div>
    </div>
  );
}
