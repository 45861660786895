import axios from "axios";

const CONSUMER_KEY = "ck_0dedd370358444b6ae1e8980c172c798b7e75f29";
const CONSUMER_SECRET = "cs_ef06e199750a48d763a27924fafb43083e4260b8";

export const AUTH_URL = "https://www.cush-living.com/";

// export const BASE_URL = "http://localhost:4001/api/";
export const BASE_URL = "https://cush-living-node-app.vercel.app/api/";

export const ASSET_URL = "https://cush-image.s3.amazonaws.com/";

export const MAILCHIMP_URL =
  "https://cush-living.us1.list-manage.com/subscribe/post?u=e0777dbd13a544850dcf58dcb&id=e0d781b00f&f_id=0089eee5f0";

// axios.interceptors.request.use(function (config) {
//   config.headers.Authorization =
//     "Basic " + btoa(`${CONSUMER_KEY}:${CONSUMER_SECRET}`);
//   return config;
// });

export const getFilterData = () => {
  return axios.get(AUTH_URL + "wp-json/cushions-api/v1/brands-patterns");
};

export const getFabricData = () => {
  return axios.get(AUTH_URL + "wp-json/cushions-api/v1/fabrics");
};

export const login = (body) => {
  return axios.post(AUTH_URL + "wp-json/cushions-api/v1/login", body);
};

export const logout = (userId) => {
  return axios.post(AUTH_URL + "wp-json/cushions-api/v1/logout", userId);
};

export const signup = (body) => {
  return axios.post(AUTH_URL + "wp-json/cushions-api/v1/signup", body);
};

export const createProduct = (body) => {
  const URL = `${AUTH_URL}wp-json/wc/v3/products`;
  return axios.post(URL, body);
};

export const addProductsToCart = ({ id, quantity = 1 }) => {
  return `${AUTH_URL}cart/?add-to-cart=${id}&quantity=${quantity}`;
};

export class FabricModel {
  static getBrands() {
    return axios.get(BASE_URL + "brands/");
  }

  static getColors() {
    return axios.get(BASE_URL + "colors/");
  }

  static getPatterns() {
    return axios.get(BASE_URL + "patterns/");
  }

  static getCollections() {
    return axios.get(BASE_URL + "collections/");
  }

  static getFabrics(pageSize, page, filters) {
    let filterQuery = "";
    if (filters) {
      filterQuery = filters.brand ? "&brand=" + filters.brand : "";
      filterQuery = filters.color
        ? filterQuery + "&color=" + filters.color
        : filterQuery;
      filterQuery = filters.pattern
        ? filterQuery + "&pattern=" + filters.pattern
        : filterQuery;
      filterQuery = filters.minPrice
        ? filterQuery + "&minPrice=" + filters.minPrice
        : filterQuery;
      filterQuery = filters.maxPrice
        ? filterQuery + "&maxPrice=" + filters.maxPrice
        : filterQuery;
      filterQuery = filters.title
        ? filterQuery + "&title=" + filters.title
        : filterQuery;
      filterQuery = filters.sortBy
        ? filterQuery + "&sortBy=" + filters.sortBy
        : filterQuery;
    }

    return axios.get(
      BASE_URL + "fabrics/?pageSize=" + pageSize + "&page=" + page + filterQuery
    );
  }

  static getFabricById(id) {
    return axios.get(BASE_URL + "fabrics/" + id);
  }
}

export const createPaymentIntent = (body) => {
  return axios.post(BASE_URL + "payments/create-payment-intent", body);
};

export class OrderModel {
  static createOrder(body) {
    return axios.post(BASE_URL + "orders/", body);
  }
  static getUserOrders(userId) {
    return axios.get(BASE_URL + "orders/?userId=" + userId);
  }
}
