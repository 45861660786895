import { useFormik } from "formik";
import React from "react";
import { newsletterSchema } from "../../utils/schema";
import Input from "./Input";
import { useEffect } from "react";
import { toast } from "react-toastify";

export default function NewsletterForm({ status, message, onSendForm }) {
  const onSubmitForm = (values) => {
    onSendForm(values);
  };
  const { values, handleChange, handleSubmit, errors, touched, resetForm } =
    useFormik({
      initialValues: { EMAIL: "" },
      validationSchema: newsletterSchema,
      onSubmit: onSubmitForm,
    });

  useEffect(() => {
    if (status === "error") {
      toast.error(message);
    }
    if (status === "success") {
      toast.success(message);
      resetForm();
    }
  }, [status]);

  return (
    <>
      <form className="d-flex pb-2 mb-2" onSubmit={handleSubmit}>
        <Input
          type="email"
          name="EMAIL"
          placeholder="ENTER EMAIL ADDRESS"
          className="flex-grow-1"
          value={values.EMAIL}
          onChange={handleChange}
          disabled={status === "sending"}
        />
        <button
          type="submit"
          className="bg-transparent border-0 text-uppercase py-2 px-3"
          disabled={values.EMAIL === "" || status === "sending"}
        >
          {status === "sending" ? "Sending" : "Submit"}
        </button>
      </form>
      {touched.EMAIL && errors.EMAIL && (
        <div className="text-danger">{errors.EMAIL}</div>
      )}
    </>
  );
}
