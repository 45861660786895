/**
 * 👉 This component renders the page for Shapes Selection.
 * Overview:
 * When a user select any Cushion Type, it redirects the user to the shapes
 * page with hidden data that is further used to generate the shapes list.
 *
 * 📅 Last Modified: 19 - 01 - 2023
 */
import React from "react";
import Header from "./partials/Header";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { InputCushionType } from "../components";
import { shapesListBuilder } from "../utils";

export default function PgCushionShapesSelector() {
  const { shape } = useParams();
  const { state } = useLocation();
  const navigate = useNavigate();
  // console.log('🚀 Cushion Sahpes - Page - Line 19', state);
  const shapes = shapesListBuilder(state.shapes);
  //   console.log('🚀 Cushion Shapes - Page - Line 12', shapes);
  return (
    <div className="cushion-selector">
      <div className="container">
        <div className="row">
          <div className="col-md-12 py-5">
            <Header />
            <h1 className="fs-4 mt-5 mb-0">Select Cushion Shape</h1>
            <hr />
            <div className="d-flex gap-3">
              {shapes.map((item, i) => {
                return (
                  <div key={i} className="shape-card mb-3">
                    <InputCushionType data={item} />
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
