import React from "react";

export default function NavController({
  pages,
  activePage,
  pageHandler,
  finalStep,
  finalStepActivator,
  showNavController,
}) {
  const nextHandler = () => {
    pageHandler(activePage + 1);
  };
  const backHandler = () => {
    pageHandler(activePage - 1);
  };
  if (pages == 0) {
    return <></>;
  }
  if (finalStep) {
    return <></>;
  }
  return (
    <div className="my-4">
      <div className="d-flex justify-content-end">
        {/* {
                activePage == 0?
                ''
                :
                <button className='btn btn-primary me-2' onClick={backHandler}>Back</button>
            } */}
        {activePage === pages ? (
          <button
            className="btn btn-success"
            onClick={() => finalStepActivator(true)}
            disabled={!showNavController}
          >
            Finalize
          </button>
        ) : (
          <button
            className="btn btn-primary"
            onClick={nextHandler}
            disabled={!showNavController}
          >
            Next
          </button>
        )}
      </div>
    </div>
  );
}
