import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Select from "react-select";
import ColorFilter from "./ColorFilter";
import { getFilterData } from "../../../../service";

export default function FabricGalleryFilter({ onFilter }) {
  const title = useSelector((state) => state.fabric);

  const [activeBrand, setActiveBrand] = useState(null);
  const [activePattern, setActivePattern] = useState(null);
  const [activeSort, setActiveSort] = useState(null);
  const [searchTitle, setSearchTitle] = useState(null);
  const [activeColor, setActiveColor] = useState(null);

  const [brands, setBrands] = useState([]);
  const [patterns, setPatterns] = useState([]);
  const [sorting, setSorting] = useState([
    {
      label: "Sort By",
      value: "",
    },
    {
      label: "Price (Hight to Low)",
      value: "lth",
    },
    {
      label: "Price (Low to High)",
      value: "htl",
    },
  ]);

  useEffect(() => {
    const fetchBrands = async () => {
      try {
        let response = await getFilterData();
        response = response.data[0];
        let tempBrands = response?.brands.map((item) => {
          return {
            label: item.title,
            value: item.id,
          };
        });
        setBrands(tempBrands);

        let tempPatterns = response?.patterns.map((item) => {
          return {
            label: item.title,
            value: item.id,
          };
        });
        setPatterns(tempPatterns);
        // console.log("🚀 API Response:", response);
      } catch (error) {
        console.log("Error:", error);
      }
    };
    fetchBrands();
  });

  const onFilterButtonClick = () => {
    onFilter({
      title: searchTitle ?? null,
      brand: activeBrand?.value ?? null,
      pattern: activePattern?.value ?? null,
      sort: activeSort ?? null,
      color: activeColor ?? null,
    });
  };

  const onColorSelect = (color) => {
    setActiveColor(color);
  };

  return (
    <div className="row">
      <ColorFilter onColorSelect={onColorSelect} />
      <div className="col-md-12">
        <div className="row fabric-gallery-filter mb-3 align-items-center border-bottom pb-4">
          <div className="col-4">
            <label className="fw-bold mb-2">Search Keyword</label>
            <input
              className="form-control"
              placeholder="Type Keyword..."
              value={searchTitle}
              onChange={(e) => setSearchTitle(e.target.value)}
            />
          </div>
          <div className="col">
            <label className="fw-bold mb-2">Brand</label>
            <Select
              value={activeBrand}
              options={brands}
              onChange={(value) => setActiveBrand(value)}
            />
          </div>
          <div className="col">
            <label className="fw-bold mb-2">Pattern</label>
            <Select
              value={activePattern}
              options={patterns}
              onChange={(value) => setActivePattern(value)}
            />
          </div>
          <div className="col">
            <label className="fw-bold mb-2">Sort By</label>
            <select
              className="form-control"
              onChange={(e) => setActiveSort(e.target.value)}
            >
              {sorting.map((item, index) => {
                return (
                  <option key={index} value={item.value}>
                    {item.label}
                  </option>
                );
              })}
            </select>
          </div>
          <div className="col-2">
            <button
              className="btn btn-primary w-100 mt-4"
              onClick={onFilterButtonClick}
            >
              Filter
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
