import React, { useEffect, useState } from "react";
import { FiLogOut } from "react-icons/fi";
import { Button } from "../../components";

export default function Header() {
  const [user, setUser] = useState(null);
  useEffect(() => {
    const fetchUser = () => {
      let userData = localStorage.getItem("user");
      if (userData) {
        userData = JSON.parse(userData);
        setUser(userData);
      }
    };
    fetchUser();
  }, []);
  const onLogout = () => {
    localStorage.removeItem("user");
    setUser(null);
  };
  return (
    <>
      <div className="d-flex justify-content-between align-items-center">
        <a href="/">
          <img
            className="logo"
            src={`/images/logo.png`}
            alt="Cush-Living"
            width={200}
          />
        </a>
        <div className="action">
          <div className="d-flex gap-2">
            <a className="btn btn-primary">Go Back to Home</a>
            {user && (
              <Button
                className="btn btn-primary"
                onClick={onLogout}
                icon={<FiLogOut />}
              >
                Logout
              </Button>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
