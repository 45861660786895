import React, { useState } from "react";
import CheckButton from "./CheckButton";

export default function UNOBox({ selectedSupport, support, onChange }) {
  const options = [
    {
      label: "UNO",
      value: "uno",
    },
    {
      label: "DUO",
      value: "duo",
    },
  ];
  const [style, setStyle] = useState(selectedSupport);
  const handler = (value) => {
    setStyle(value);
    onChange(value);
  };
  const [includePiping, setIncludePiping] = useState(false);
  const pipingHandler = () => {
    setIncludePiping(!includePiping);
  };
  return (
    <div className="my-2">
      <h5 className="heading">Select Style:</h5>
      <div className="">
        <CheckButton
          tick={includePiping}
          title="Include Piping"
          onClick={pipingHandler}
        />
      </div>
      <div className="row">
        {options.map((option, i) => {
          var containerClass = "";
          if (option.value === style) {
            containerClass = "active";
          }
          if (support === "uno" && option.value === "duo") {
            return "";
          }
          return (
            <div key={i} className="col-2">
              <button
                className={`btn btn-uno ${containerClass} w-100`}
                onClick={() => handler(option.value)}
              >
                {option.label}
              </button>
            </div>
          );
        })}
        <div className="col-md-4">
          <p className="m-0 mb-1">
            <span className="fw-bold">UNO:</span> Select up to 2 Fabrics!
          </p>
          {support === "duo" ? (
            <p className="m-0 mb-1">
              <span className="fw-bold">DUO:</span> Select up to 4 Fabrics!
            </p>
          ) : (
            ""
          )}
        </div>
      </div>

      <hr />
    </div>
  );
}
