/**
 * 👉 This component renders Three D Model and Configurations.
 *
 * 📅 Last Modified: 19 - 01 - 2023
 */
import React, { useEffect, useState } from "react";
import Preview from "./Preview";
import Configurator from "./Configurator";
import { useSelector } from "react-redux";
import Header from "./Header";
export default function ThreeDRenderer({ modelObject, data }) {
  return (
    <div className="three-d-renderer">
      <Header />
      <div className="d-flex">
        <Preview />
        <Configurator />
      </div>
    </div>
  );
}
