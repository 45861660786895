import {
  SET_MODEL,
  SET_HIDDEN_MATERIALS,
  SET_TEXTURE,
  SET_DIMENSIONS,
  SET_LABOR_COST,
  SET_PRICE,
  SET_TIES,
} from "../actionTypes/actionTypes";
import { modelSelector, uniqueMaterialsProvider } from "../../utils";

const initialState = {
  modelConfigurations: null,
  hiddenItems: null,
  fabrics: [],
  dimensions: null,
  pricing: {
    labor: {
      cutAndSew: 0,
      fillLabor: 0,
      laborTotal: 0,
    },
    rawMaterials: {
      ties: {
        number: 0,
      },
    },
  },
  price: 0,
};

const modelReducer = (state = initialState, action) => {
  switch (action.type) {
    //Setting Model
    case SET_MODEL:
      // console.log("🔥 SET MODEL REDUCER - Line 15 - Object", action.payload);
      // return Object.assign(state, action.payload);
      return {
        ...state,
        ...action.payload,
      };

    //Hide Materials
    case SET_HIDDEN_MATERIALS:
      // return Object.assign(state, action.payload);
      return {
        ...state,
        ...action.payload,
      };

    //Set Textures
    case SET_TEXTURE:
      return {
        ...state,
        fabrics: [...action.payload],
      };
    //Set Dimensions
    case SET_DIMENSIONS:
      return {
        ...state,
        dimensions: action.payload,
      };
    //Set Labor Cost
    case SET_LABOR_COST:
      return {
        ...state,
        pricings: {
          ...state.pricings,
          labor: action.payload,
        },
      };
    //Set Price
    case SET_PRICE:
      return {
        ...state,
        price: action.payload,
      };
    //Set Ties
    case SET_TIES:
      return {
        ...state,
        pricing: {
          ...state,
          pricings: {
            ...state.pricings,
            ties: action.payload,
          },
        },
      };
    default:
      return state;
  }
};

export default modelReducer;
