import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  addConfiguration,
  setConfigurations,
  setDimensions,
  setPrice,
} from "../../../../redux/actions";
import CustomModal from "../../CustomModal";
import ModalContent from "./ModalContent";
import { calculateLaborCost } from "../../../../utils";
export default function DimensionsForm({ dimensions, handler }) {
  //State Variables
  const [thickness, setThickness] = useState(dimensions.default.thickness);
  const [width, setWidth] = useState(dimensions.default.width);
  const [depth, setDepth] = useState(dimensions.default.depth);
  //Dispatch
  const dispatch = useDispatch();
  //Getting Model State from Redux
  const state = useSelector((state) => state);
  //State Varaibles
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");

  useEffect(() => {
    const fetchDefualtPrices = () => {
      let obj = {
        width: dimensions.default.width,
        thickness: dimensions.default.thickness,
        depth: dimensions.default.depth,
      };
      dispatch(
        setConfigurations({
          dimensions: obj,
        })
      );
    };
    fetchDefualtPrices();
  }, []);
  //Handler
  const dataHandler = () => {
    if (
      parseFloat(width) > dimensions.max.width ||
      parseFloat(depth) > dimensions.max.depth ||
      parseFloat(thickness) > dimensions.max.thickness
    ) {
      setSuccess("");
      if (
        parseFloat(width) < dimensions.max.width ||
        parseFloat(depth) < dimensions.max.depth ||
        parseFloat(thickness < dimensions.min.thickness)
      ) {
        setError(
          `Error: Min value for dimesnions are thickness = ${dimensions.min.thickness}, width=${dimensions.min.width}, depth=${dimensions.min.depth}.`
        );
      } else {
        setError(
          `Error: Min value for dimesnions are thickness = ${dimensions.max.thickness}, width=${dimensions.max.width}, depth=${dimensions.max.depth}.`
        );
      }
    } else {
      setError("");
      let dimensions = {
        thickness: thickness,
        width: width,
        depth: depth,
      };
      // dispatch(setDimensions(dimensions));
      dispatch(
        setConfigurations({
          dimensions: dimensions,
        })
      );
      const laborCost = calculateLaborCost(dimensions);
      dispatch(setPrice(laborCost.totalCost));
      // console.log("State:", state);
      setSuccess("✔️ Dimensions saved!");
      // console.log("🚀 Labor Cost:", laborCost);
    }
  };

  //Thickness Options
  const thicknessOptions = [2.0, 3.0, 4.0, 5.0, 6.0, 7.0, 8.0, 9.0, 10.0];

  //JSX
  return (
    <div className="dimension-form my-2">
      <div className="row">
        <div className="col-md-12">
          <div className="d-flex align-items-center">
            <h5 className="heading mb-0">INPUT SIZING(IN INCHES)</h5>
            <CustomModal title="Input Sizing">
              <ModalContent />
            </CustomModal>
          </div>
        </div>
        <div className="col">
          <div className="d-flex align-items-center">
            <label className="me-3">Thick</label>
            {/* <input
              type="number"
              className="form-control"
              // step={0.5}
              value={thickness}
              onChange={(e) => setThickness(e.target.value)}
            /> */}
            <select
              value={thickness}
              className="form-control"
              onChange={(e) => setThickness(e.target.value)}
            >
              {thicknessOptions.map((item, i) => {
                return (
                  <option key={i} value={item}>
                    {item}
                  </option>
                );
              })}
            </select>
          </div>
        </div>
        <div className="col">
          <div className="d-flex align-items-center">
            <label className="me-3">Width</label>
            <input
              type="number"
              className="form-control"
              step={0.5}
              value={width}
              onChange={(e) => setWidth(e.target.value)}
            />
          </div>
        </div>
        <div className="col">
          <div className="d-flex align-items-center">
            <label className="me-3">Depth</label>
            <input
              type="number"
              className="form-control"
              step={0.5}
              value={depth}
              onChange={(e) => setDepth(e.target.value)}
            />
          </div>
        </div>

        <div className="col-md-12 my-2 text-end">
          <button className="btn btn-primary" onClick={dataHandler}>
            Save Dimensions
          </button>
        </div>
      </div>
      {error !== "" ? (
        <div className="row">
          <div className="col-md-12">
            <p className="text-danger mb-0">{error}</p>
          </div>
        </div>
      ) : (
        ""
      )}
      {success !== "" ? (
        <div className="row">
          <div className="col-md-12">
            <p className="text-success mb-0">{success}</p>
          </div>
        </div>
      ) : (
        ""
      )}
      <hr />
    </div>
  );
}
