import React from "react";

export default function Input({ className, label, error, touched, ...props }) {
  return (
    <div className={`${className}`}>
      {label && <label className="form-label">{label}</label>}
      <input className="form-control" {...props} />
      {touched && error && <div className="text-danger">{error}</div>}
    </div>
  );
}
