import React from "react";

export default function Steps({ activeStep = "Shape" }) {
  const steps = ["Shape", "Style & Design", "Review"];
  return (
    <div className="d-flex justify-content-center align-items-center steps-container">
      {steps.map((step, i) => {
        var styleClass = "";
        if (step === activeStep) {
          styleClass = "active";
        }
        return (
          <div
            key={i}
            className={`d-flex align-items-center step ${styleClass}`}
          >
            <div className="number me-2">{i + 1}</div>
            <div className="title">{step}</div>
          </div>
        );
      })}
    </div>
  );
}
