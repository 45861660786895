import { combineReducers } from "redux";
import configurationReducer from "./configurationReducer";
import modelReducer from "./modelReducer";
import fabricGalleryReducer from "./fabricGalleryReducer";
import cartReducer from "./cartReducer";
import userReducer from "./userReducer";
export default combineReducers({
  configurations: configurationReducer,
  model: modelReducer,
  fabric: fabricGalleryReducer,
  cart: cartReducer,
  user: userReducer,
});
