import React from "react";
import data from "./data/cushions";

export default function CushionInfo() {
  return (
    <div className="">
      <h2 className="bg-secondary text-uppercase text-white py-2 px-3">
        Additional Information
      </h2>
      <div className="faqs">
        {data.map((item, i) => {
          return (
            <div key={i} className="my-2">
              <h3 className="fs-6 fw-bold">{item.q}</h3>
              {item.ans}
            </div>
          );
        })}
      </div>
    </div>
  );
}
