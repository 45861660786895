import {
  ADD_CONFIGURATION,
  SET_CUSHION_CONFIGURATION,
  SET_CUSHION_SHAPE,
  SET_CUSHION_TYPE,
} from "../actionTypes/actionTypes";

const initialState = {
  cushionType: null,
  cushionShape: null,
  validated: null,
};

const configurationReducer = (state = initialState, action) => {
  let newState = action.payload;

  switch (action.type) {
    //Set Cushion Type
    case SET_CUSHION_TYPE:
      state = Object.assign({}, state, newState);
      return state;
    //Set Cushion Shape
    case SET_CUSHION_SHAPE:
      state = Object.assign({}, state, newState);
      return state;
    // Set Options
    case SET_CUSHION_CONFIGURATION:
      state = Object.assign({}, state, newState);
      return state;

    default:
      return state;
  }
};

export default configurationReducer;
