import { useFormik } from "formik";
import React from "react";
import { ToastContainer, toast } from "react-toastify";
import { Input } from "../components";
import { signupSchema } from "../utils/schema";
import { Button, Col, Container, Row } from "react-bootstrap";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { signup } from "../service";

export default function Signup() {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const onSignup = async (values) => {
    setLoading(true);
    try {
      await signup(values);
      toast.success("Account created successfully!");
      navigate("/login");
      setLoading(false);
    } catch (error) {
      toast.error("Error while signing up!");
      console.log(error);
      setLoading(false);
    }
  };
  const initialValues = {
    firstName: "",
    lastName: "",
    email: "",
    password: "",
  };
  const { values, handleChange, handleBlur, handleSubmit, touched, errors } =
    useFormik({
      initialValues: initialValues,
      validationSchema: signupSchema,
      onSubmit: onSignup,
    });
  return (
    <section
      className="login"
      style={{ backgroundImage: "url('/images/login-bg.jpg')" }}
    >
      <div className="overlay" />
      <ToastContainer />
      <Container className="vh-100 d-flex align-items-center">
        <Row className="content align-items-center">
          <Col lg={8}>
            <h2>Cush Living</h2>
            <p>
              Welcome to Cush Living, a place for custom made cushoin & pillows
              for outdoor and indoor living.
            </p>
          </Col>
          <Col lg={4}>
            <div className="card">
              <div className="card-body">
                <h3 className="text-center mb-4">Signup</h3>
                <form onSubmit={handleSubmit}>
                  <Input
                    className="my-2"
                    type="text"
                    name="firstName"
                    value={values.firstName}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={errors.firstName}
                    touched={touched.firstName}
                    label="First Name"
                    placeholder="Enter First Name"
                    disabled={loading ? "true" : undefined}
                  />
                  <Input
                    className="my-2"
                    type="text"
                    name="lastName"
                    value={values.lastName}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={errors.lastName}
                    touched={touched.lastName}
                    label="Last Name"
                    placeholder="Enter Last Name"
                    disabled={loading ? "true" : undefined}
                  />
                  <Input
                    className="my-2"
                    type="email"
                    name="email"
                    value={values.email}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={errors.email}
                    touched={touched.email}
                    label="Enter Email"
                    placeholder="cushliving@example.com"
                    disabled={loading ? "true" : undefined}
                  />
                  <Input
                    className="my-2"
                    type="password"
                    name="password"
                    value={values.password}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={errors.password}
                    touched={touched.password}
                    label="Enter Password"
                    placeholder="Password"
                    disabled={loading ? "true" : undefined}
                  />
                  <div className="mb-2">
                    <Button
                      type="submit"
                      className="my-2 w-100"
                      disabled={loading ? "true" : undefined}
                    >
                      Signup
                    </Button>
                  </div>
                  <div className="text-center">
                    <a
                      href="/login"
                      style={{ pointerEvents: loading ? "none" : "auto" }}
                    >
                      Already have an account?
                    </a>
                  </div>
                </form>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
}
