import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  TextChoices,
  DimensionsForm,
  ImageChoices,
  // FabricGallery,
  // FabricPicker,
  ShowHide,
  TiesPicker,
  CTPicker,
  FillTypePicker,
} from "../../components";
import FabricGallery from "../common/controls/fabricgallery";
import NavController from "./NavController";
import ReviewOrder from "./ReviewOrder";

export default function Configurator() {
  const modelState = useSelector((state) => state.model);
  const configurations = useSelector((state) => state.configurations);

  const [pages, setPages] = useState(null);
  const [activePage, setActivePage] = useState(0);
  const [showFinalStep, setShowFinalStep] = useState(false);

  // console.log("Model State, Configurator:", modelState);

  useEffect(() => {
    const updatePages = () => {
      setPages(modelState.modelConfigurations?.configurations.length);
    };
    updatePages();
  }, [modelState]);

  return (
    <>
      {modelState.modelConfigurations && (
        <div className="configurator-container">
          <div className="configurator-container">
            {
              //If Supports Dimensions
              modelState.modelConfigurations.supportsDimensions &&
              activePage === 0 ? (
                <DimensionsForm
                  dimensions={modelState.modelConfigurations.dimensions}
                />
              ) : (
                ""
              )
            }
            {showFinalStep ? (
              <ReviewOrder />
            ) : (
              modelState?.modelConfigurations?.configurations[activePage]?.map(
                (configuration, i) => {
                  if (
                    configuration.conditions != undefined &&
                    configuration.conditions.depends.length > 0
                  ) {
                  }
                  switch (configuration.type) {
                    case "txt-choices":
                      return <TextChoices key={i} data={configuration} />;
                    case "img-choices":
                      return <ImageChoices key={i} data={configuration} />;
                    case "ct-picker":
                      return <CTPicker key={i} data={configuration} />;
                    case "show-hide":
                      return <ShowHide key={i} data={configuration} />;
                    case "ties-picker":
                      return <TiesPicker key={i} data={configuration} />;
                    case "fill-type":
                      return <FillTypePicker key={i} data={configuration} />;
                    // case "fabric-picker":
                    // case "ct-picker":
                    //   return <FabricGallery key={i} data={configuration} />;
                  }
                }
              )
            )}
            {/* {pages}
            {activePage} */}
            <NavController
              pages={pages - 1}
              activePage={activePage}
              pageHandler={setActivePage}
              finalStep={showFinalStep}
              finalStepActivator={setShowFinalStep}
              showNavController={
                configurations.validated !== null
                  ? configurations.validated
                  : true
              }
            />
          </div>
        </div>
      )}
    </>
  );
}
