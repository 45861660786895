import React from "react";
import Spinner from "./Spinner";

export default function Button({
  children,
  variant,
  className,
  loading,
  icon,
  ...props
}) {
  let bgClass, textClass;

  switch (variant) {
    case "primary":
      bgClass = "bg-primary";
      textClass = "text-white";
      break;
    case "secondary":
      bgClass = "bg-secondary";
      textClass = "text-white";
      break;
    case "success":
      bgClass = "bg-success";
      textClass = "text-white";
      break;
    case "warning":
      bgClass = "bg-warning";
      textClass = "text-dark";
      break;
    case "danger":
      bgClass = "bg-danger";
      textClass = "text-white";
      break;
    case "transparent-gray":
      bgClass = "bg-transparent-gray";
      textClass = "text-white";
      break;
    default:
      bgClass = "bg-primary";
      textClass = "text-white";
  }

  return (
    <button className={`btn ${bgClass} ${textClass} ${className}`} {...props}>
      <div className="d-flex gap-2">
        {icon && <div className="">{icon}</div>}
        <div className="text">{children}</div>
        {loading && <Spinner />}
      </div>
    </button>
  );
}
