/**
 * 👉 This component loads the model for:
 * Cushion Type: Seat
 * Shape: Squared Corners
 *
 * 👨‍⚖️ Author: Awais Ahmad
 * 🌐 Author Github: @itsahmadawais
 *
 * 📅 Created: 28 - 03 - 2023
 * 📅 Last Modified: 28 - 03 - 2023
 */

/**
 * 🚀 Auto-generated by:
 * https://gltf.pmnd.rs/
 * https://github.com/pmndrs/gltfjsx
 */

import React, { Suspense, useState } from "react";
import { useGLTF } from "@react-three/drei";
import ModelBase from "../../ModelBase";

const MODEL = "/models/seat/roundedcorners.glb";

export default function Model(props) {
  const { nodes, materials } = useGLTF(MODEL);

  const mesh_list = [
    {
      name: "Seat_Rounded_Corners_Center_Seam_Bottom",
      geometry: nodes.Seat_Rounded_Corners_Center_Seam_Bottom.geometry,
      material: materials["Seat_Rounded_Corners_Center_Seam_Bottom"],
      materialIndex: "Seat_Rounded_Corners_Center_Seam_Bottom",
    },
    {
      name: "Seat_Rounded_Corners_Center_Seam_Pipe",
      geometry: nodes.Seat_Rounded_Corners_Center_Seam_Pipe.geometry,
      material: materials["Seat_Rounded_Corners_Center_Seam_Pipe.001"],
      materialIndex: "Seat_Rounded_Corners_Center_Seam_Pipe.001",
    },
    {
      name: "Seat_Rounded_Corners_Center_Seam_Top",
      geometry: nodes.Seat_Rounded_Corners_Center_Seam_Top.geometry,
      material: materials["Seat_Rounded_Corners_Center_Seam_Pipe.002"],
      materialIndex: "Seat_Rounded_Corners_Center_Seam_Pipe.002",
    },
    {
      name: "BACK_CORNER_TIES005",
      geometry: nodes.BACK_CORNER_TIES005.geometry,
      material: materials["4_BACK_CORNER_TIES"],
      materialIndex: "4_BACK_CORNER_TIES",
      position: [-0.3, 0.01, -0.3],
      rotation: [0, Math.PI / 4, 0],
    },
    {
      name: "BACK_TIES_CENTERED004",
      geometry: nodes.BACK_TIES_CENTERED004.geometry,
      material: materials["4_BACK_TIES_CENTERED"],
      materialIndex: "4_BACK_TIES_CENTERED",
      position: [0, 0.01, -0.32],
    },
    {
      name: "FRONT_CORNER_TIES005",
      geometry: nodes.FRONT_CORNER_TIES005.geometry,
      material: materials["4_FRONT_CORNER_TIES"],
      materialIndex: "4_FRONT_CORNER_TIES",
      position: [0.01, 0.01, 0.29],
      rotation: [Math.PI, -0.84, Math.PI],
    },
    {
      name: "FRONT_TIES_CENTERED004",
      geometry: nodes.FRONT_TIES_CENTERED004.geometry,
      material: materials["2_FRONT_TIES_CENTERED"],
      materialIndex: "2_FRONT_TIES_CENTERED",
      position: [-0.11, 0.04, 0.34],
      rotation: [Math.PI, 0, Math.PI],
    },
    {
      name: "SIDE_TIES005",
      geometry: nodes.SIDE_TIES005.geometry,
      material: materials["2_SIDE_TIES"],
      materialIndex: "2_SIDE_TIES",
      position: [0.32, 0.04, 0],
      rotation: [0, -1.57, 0],
    },
  ];

  return <ModelBase modelProps={props} meshList={mesh_list} />;
}
Model.deafultProps = {
  hiddenItems: [],
  textures: {},
};

useGLTF.preload(MODEL);
