import React, { useState } from "react";
import FabricGalleryCard from "./FabricGalleryCard";
import FabricGalleryFilter from "./FabricGalleryFilter";

export default function FabricGallery({ fabricData }) {
  const [filteredData, setFilteredData] = useState(fabricData);

  const onFilter = ({
    title = null,
    brand = null,
    pattern = null,
    sort = null,
    color = null,
  }) => {
    let tempFabric = fabricData;
    if (brand != null) {
      tempFabric = tempFabric.filter((item) => {
        if (item?.brands?.length > 0) {
          if (item.brands[0].id === brand) {
            return true;
          }
        }
        return false;
      });
    }
    if (pattern != null) {
      tempFabric = tempFabric.filter((item) => {
        if (item?.patterns?.length > 0) {
          if (item.patterns[0].id === pattern) {
            return true;
          }
        }
        return false;
      });
    }
    if (title != null) {
      tempFabric = tempFabric.filter((item) => {
        if (item.title.includes(title)) {
          return true;
        }
        return false;
      });
    }
    if (sort != null) {
      tempFabric = tempFabric.sort((a, b) => {
        console.log(a, b);
        return sort === "lth"
          ? a?.price[0] ?? 0 - b?.price[0] ?? 0
          : b?.price[0] ?? 0 - a?.price[0] ?? 0;
      });
    }
    if (color != null && color?.colorCode !== "multi") {
      tempFabric = tempFabric.filter((item) => {
        let hasColors = item?.colors.filter((fabricColor) => {
          if (fabricColor.color[0] === color.colorCode) {
            return true;
          }
          return false;
        });
        if (hasColors.length > 0) {
          return true;
        }
        return false;
      });
    }
    setFilteredData(tempFabric);
  };
  return (
    <div className="container fabric-gallery">
      <FabricGalleryFilter onFilter={onFilter} />
      <div className="row flex-wrap">
        {filteredData.map((item, i) => {
          return (
            <div key={i} className="col-md-2 my-2">
              <FabricGalleryCard data={item} />
            </div>
          );
        })}
      </div>
    </div>
  );
}
