import React, { useState } from "react";
import QuantitySelector from "./QuantitySelector";
import { useNavigate } from "react-router-dom";

import { FaCartPlus } from "react-icons/fa";
import { useSelector } from "react-redux";
import { extractFabricImage } from "../../utils";
import { addProductsToCart, createProduct } from "../../service";

export default function ReviewOrder() {
  const navigate = useNavigate();
  const [quantity, setQuantity] = useState(1);
  const [additionalInfo, setAdditionalInfo] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const model = useSelector((state) => state.model);
  const configurations = useSelector((state) => state.configurations);
  const addToCartHandler = async () => {
    // navigate("/success-cart");
    try {
      console.log(configurations);
      let body = {
        name: `${configurations.cushionType} | ${configurations.cushionShape}`,
        type: `simple`,
        regular_price: configurations.totalPrice,
        description: `\nAdditional Information:\n${additionalInfo}`,
        meta_data: [
          {
            key: "visibility",
            value: "true",
          },
          {
            key: "admin_details",
            value: JSON.stringify(configurations),
          },
        ],
      };
      let response = await createProduct(body);
      let id = response.data.id;
      setIsSubmitting(true);
      window.location.href = addProductsToCart({ id, quantity });
      console.log(response);
    } catch (error) {
      console.log("Error:", error);
    }
  };
  return (
    <div className="review-order">
      <h3 className="heading">Review Order</h3>
      <div className="content">
        <div className="row my-4">
          <div className="col-md-6">
            <div className="d-flex flex-wrap gap-2">
              {Object.keys(configurations.cushionStructure.fabrics).map(
                (key) => {
                  return (
                    <div key={key} className="card card-fabric">
                      <div className="card-body">
                        <img
                          src={extractFabricImage(
                            configurations.cushionStructure.fabrics[key]
                          )}
                          className="img w-100"
                        />
                        <p className="p small mb-2 mt-2 px-2">
                          {key} /{" "}
                          {configurations.cushionStructure.fabrics[key].title}
                        </p>
                      </div>
                    </div>
                  );
                }
              )}
            </div>
          </div>
          <div className="col-md-6">
            <ul className="features-list">
              {/* Cushion */}
              <li>
                <div className="d-flex gap-2">
                  <p className="label mb-0 fw-bold">Product:</p>
                  <p className="value mb-0">
                    {model.modelConfigurations.productTitle}
                  </p>
                </div>
              </li>
              {/* Shape */}
              <li>
                <div className="d-flex gap-2">
                  <p className="label mb-0 fw-bold">Shape:</p>
                  <p className="value mb-0">
                    {model.modelConfigurations.shape}
                  </p>
                </div>
              </li>
              {/* Structure */}
              <li>
                <div className="d-flex gap-2">
                  <p className="label mb-0 fw-bold">Structure:</p>
                  <p className="value mb-0">
                    {configurations.cushionStructure.cushionStructure}
                  </p>
                </div>
              </li>
              {/* Size */}
              {configurations?.dimensions && (
                <li>
                  <div className="d-flex gap-2">
                    <p className="label mb-0 fw-bold">Size:</p>
                    {/* <p className="value mb-0">22W x 22L x 4T</p> */}
                    <p className="value mb-0">
                      {configurations.dimensions.width}W x{" "}
                      {configurations.dimensions.depth}D x{" "}
                      {configurations.dimensions.thickness}T
                    </p>
                  </div>
                </li>
              )}
              {/* Fabric */}
              {/* <li>
                <div className="d-flex gap-2">
                  <p className="label mb-0 fw-bold">Fabric:</p>
                  <p className="value mb-0">Natural 5404-0000</p>
                </div>
              </li> */}
              {/* Trimming */}
              {/* <li>
                <div className="d-flex gap-2">
                  <p className="label mb-0 fw-bold">Trimming:</p>
                  <p className="value mb-0">Capri 5426-0000</p>
                </div>
              </li> */}
              {/* Fill Type & Batting */}
              {/* Filling */}
              {configurations?.fillType?.fillType && (
                <li>
                  <div className="d-flex gap-2">
                    <p className="label mb-0 fw-bold">Filling:</p>
                    <p className="value mb-0">
                      {configurations.fillType.fillType}
                    </p>
                  </div>
                </li>
              )}

              {/* Batting */}
              {configurations?.fillType?.batting && (
                <li>
                  <div className="d-flex gap-2">
                    <p className="label mb-0 fw-bold">Batting:</p>
                    <p className="value mb-0">Yes</p>
                  </div>
                </li>
              )}
              {/* Ties */}
              {configurations?.ties && (
                <li>
                  <div className="d-flex gap-2">
                    <p className="label mb-0 fw-bold">Ties:</p>
                    <p className="value mb-0">
                      {configurations.ties.tiesType} /
                      {configurations.ties.option}
                    </p>
                  </div>
                </li>
              )}
            </ul>
          </div>
        </div>
        <div className="row mb-4">
          <div className="col-md-8">
            <p>
              <span className="fw-bold">Note:</span> The preview is just for
              visualization purpose. The actual cushion might look a bitter
              different from the 3D model.
            </p>
            <p>
              <span className="fw-bold">Additional Info:</span> Additional Info:
              Please advise any additional details needed for your order.
              Pricing is subject to change pending any requests or adjustments.
            </p>
            <textarea
              className="form-control"
              placeholder="Start typing..."
              value={additionalInfo}
              onChange={(e) => setAdditionalInfo(e.target.value)}
            />
          </div>
        </div>
        <p className="p fw-bold mb-1">Quantity:</p>
        <QuantitySelector
          quantity={quantity}
          disabled={isSubmitting}
          setQuantity={setQuantity}
        />
        <div className="text-end">
          <button
            className="btn btn-success"
            onClick={addToCartHandler}
            disabled={isSubmitting}
          >
            <FaCartPlus /> Add to Cart
          </button>
        </div>
      </div>
    </div>
  );
}
