import React, { useEffect, useState } from "react";
import { MainLayout } from "../layout";
import { Col, Container, Row } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import { AiOutlineMinus, AiOutlinePlus } from "react-icons/ai";
import { HiScissors } from "react-icons/hi";
import { Button, CLoader } from "../components";
import { ASSET_URL, FabricModel } from "../service";
import { useDispatch } from "react-redux";
import { addToCart } from "../redux/actions";
import { toast } from "react-toastify";
import { FaSearch } from "react-icons/fa";

export default function SingleProduct() {
  const dispatch = useDispatch();
  const params = useParams();
  const productId = params.productId;
  const [productData, setProductData] = useState();
  const [loading, setLoading] = useState(true);
  const [quantity, setQuantity] = useState(2);
  const navigate = useNavigate();

  const [[x, y], setXY] = useState([0, 0]);
  const [[imgWidth, imgHeight], setSize] = useState([0, 0]);
  const [showMagnifier, setShowMagnifier] = useState(false);
  const magnifierHeight = 250;
  const magnifieWidth = 250;
  const zoomLevel = 1.8;

  const increaseQuantity = () => setQuantity(quantity + 1);
  const decreaseQuantity = () => setQuantity(quantity - 1);

  const onAddToCart = () => {
    let cartItem = Object.assign({}, productData, {
      image: `${ASSET_URL}${productData.image}`,
    });
    cartItem.quantity = quantity;
    dispatch(addToCart(cartItem));
    toast.success("Item added to cart!");
  };

  const fetchFabric = async () => {
    try {
      let res = await FabricModel.getFabricById(productId);

      if (res.status == 200) {
        setProductData(res.data.data);
        setLoading(false);
      }
    } catch (error) {
      console.log(error.message);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchFabric();
  }, []);

  return (
    <MainLayout>
      <Container className="single-product py-5">
        {loading ? (
          <div className="d-flex align-items-center justify-content-center">
            <CLoader />
          </div>
        ) : (
          <>
            <div className="topbar d-flex flex-column flex-md-row align-items-center justify-content-between px-3 py-1 mb-4">
              <img src={ASSET_URL + productData.brand.image} />
            </div>
            <Row>
              <Col md={4} lg={5}>
                <div style={{ position: "relative" }}>
                  <img
                    src={`${
                      productData.fabricShopImage
                        ? ASSET_URL + productData.fabricShopImage
                        : productData.image
                        ? ASSET_URL + productData.image
                        : ""
                    }`}
                    style={{
                      backgroundImage: "url('/images/coming-soon.png')",
                    }}
                    className="product-img rounded mb-2"
                    onMouseEnter={(e) => {
                      // update image size and turn-on magnifier
                      const elem = e.currentTarget;
                      const { width, height } = elem.getBoundingClientRect();
                      setSize([width, height]);
                      setShowMagnifier(true);
                    }}
                    onMouseMove={(e) => {
                      // update cursor position
                      const elem = e.currentTarget;
                      const { top, left } = elem.getBoundingClientRect();

                      // calculate cursor position on the image
                      const x = e.pageX - left - window.pageXOffset;
                      const y = e.pageY - top - window.pageYOffset;
                      setXY([x, y]);
                    }}
                    onMouseLeave={() => {
                      // close magnifier
                      setShowMagnifier(false);
                    }}
                  />
                  <div
                    style={{
                      display: showMagnifier ? "" : "none",
                      position: "absolute",

                      // prevent magnifier blocks the mousemove event of img
                      pointerEvents: "none",
                      // set size of magnifier
                      height: `${magnifierHeight}px`,
                      width: `${magnifieWidth}px`,
                      // move element center to cursor pos
                      top: `${y - magnifierHeight / 2}px`,
                      left: `${x - magnifieWidth / 2}px`,
                      opacity: "1", // reduce opacity so you can verify position
                      border: "1px solid lightgray",
                      backgroundColor: "white",
                      backgroundImage: `url('${
                        productData.fabricShopImage
                          ? ASSET_URL + productData.fabricShopImage
                          : productData.image
                          ? ASSET_URL + productData.image
                          : "https://placehold.co/400"
                      }')`,
                      backgroundRepeat: "no-repeat",

                      //calculate zoomed image size
                      backgroundSize: `${imgWidth * zoomLevel}px ${
                        imgHeight * zoomLevel
                      }px`,

                      //calculate position of zoomed image.
                      backgroundPositionX: `${
                        -x * zoomLevel + magnifieWidth / 2
                      }px`,
                      backgroundPositionY: `${
                        -y * zoomLevel + magnifierHeight / 2
                      }px`,
                    }}
                  ></div>
                  <div className="magnifier-icon">
                    <FaSearch size={15} />
                  </div>
                </div>
                <p>
                  Although we have made every effort to provide the most
                  accurate representation of our material selection, please take
                  note that material images may vary in color when displayed on
                  different monitors and may appear slightly different from the
                  actual material color due to several factors including
                  variations in lighting.
                </p>
              </Col>
              <Col md={8} lg={7} className="ps-3 ps-md-4">
                <h2 className="fw-semibold mb-4 text-uppercase">
                  {productData.title}
                </h2>
                <Row>
                  <Col md={4} className="fs-5 fw-semibold mb-2">
                    Brand
                  </Col>
                  <Col md={8} className="fs-5 mb-2 text-uppercase">
                    {productData.brand.title}
                  </Col>
                </Row>
                <Row>
                  <Col md={4} className="fs-5 fw-semibold mb-2">
                    Description
                  </Col>
                  <Col md={8} className="fs-5 mb-2 text-uppercase">
                    {productData.description}
                  </Col>
                </Row>
                <Row>
                  <Col md={4} className="fs-5 fw-semibold mb-2">
                    Colors
                  </Col>
                  <Col md={8} className="color-strap d-flex gap-1 mb-2">
                    {productData.colors.map((item, i) => {
                      return (
                        <div
                          key={i}
                          style={{
                            width: "30px",
                            height: "30px",
                            backgroundColor: item.hexCode,
                            border: "1px solid #00000050",
                          }}
                          className={item.title === "MULTI" ? "multi" : ""}
                        />
                      );
                    })}
                  </Col>
                </Row>
                <Row>
                  <Col md={4} className="fs-5 fw-semibold mb-2">
                    Collection
                  </Col>
                  <Col md={8} className="fs-5 mb-2 text-uppercase">
                    {productData.collections.map((item, i) => {
                      if (
                        i === productData.collections.length - 1 ||
                        productData.collections.length === 1
                      ) {
                        return item.title;
                      } else {
                        return item.title + ", ";
                      }
                    })}
                  </Col>
                </Row>
                <Row>
                  <Col md={4} className="fs-5 fw-semibold mb-2">
                    Patterns
                  </Col>
                  <Col md={8} className="fs-5 mb-2 text-uppercase">
                    {productData.patterns.map((item, i) => {
                      if (
                        i === productData.patterns.length - 1 ||
                        productData.patterns.length === 1
                      ) {
                        return item.title;
                      } else {
                        return item.title + ", ";
                      }
                    })}
                  </Col>
                </Row>
                <Row>
                  <Col md={4} className="fs-5 fw-semibold mb-2">
                    Fabric Width
                  </Col>
                  <Col md={8} className="fs-5 mb-2">
                    {productData.fabricWidth + '"'}
                  </Col>
                </Row>
                <Row>
                  <Col md={4} className="fs-5 fw-semibold mb-2">
                    Material
                  </Col>
                  <Col md={8} className="fs-5 mb-2">
                    {productData.fabricContent}
                  </Col>
                </Row>
                {!!productData.martindaleDoubleRubs && (
                  <Row>
                    <Col md={4} className="fs-5 fw-semibold mb-2">
                      Double Rubs
                    </Col>
                    <Col md={8} className="fs-5 mb-2">
                      {productData.martindaleDoubleRubs.toLocaleString()}
                    </Col>
                  </Row>
                )}
                {!!productData.repeatHorizontal && (
                  <Row>
                    <Col md={4} className="fs-5 fw-semibold mb-2">
                      Repeat Horizontal
                    </Col>
                    <Col md={8} className="fs-5 mb-2">
                      {productData.repeatHorizontal + '"'}
                    </Col>
                  </Row>
                )}
                {!!productData.repeatVertical && (
                  <Row>
                    <Col md={4} className="fs-5 fw-semibold mb-2">
                      Repeat Vertical
                    </Col>
                    <Col md={8} className="fs-5 mb-2">
                      {productData.repeatVertical + '"'}
                    </Col>
                  </Row>
                )}
                {!!productData.ozPerYard && (
                  <Row>
                    <Col md={4} className="fs-5 fw-semibold mb-2">
                      Weight
                    </Col>
                    <Col md={8} className="fs-5 mb-2">
                      {productData.ozPerYard
                        ? productData.ozPerYard + " oz/yd"
                        : productData.gramsPerMeter
                        ? productData.gramsPerMeter + "gm"
                        : "N/A"}
                    </Col>
                  </Row>
                )}
                <Row>
                  <Col md={4} className="fs-5 fw-semibold mb-2">
                    Warranty
                  </Col>
                  <Col md={8} className="fs-5 mb-2">
                    {productData.warrantyOnColorLoss + " years"}
                  </Col>
                </Row>
                <Row>
                  <Col md={4}>
                    <div className="price-box bg-light shadow-sm rounded p-2 mb-3">
                      <p className="fw-semibold mb-0">Per Yard Price</p>
                      <p className="text-primary fs-5 fw-semibold mb-0">
                        ${productData.retailPrice.toFixed(2)}
                      </p>
                    </div>
                  </Col>
                  <Col md={8}>
                    <p className="fw-semibold mb-0">Yard</p>
                    <div className="d-flex gap-4">
                      <div className="quantity-box bg-light shadow-sm rounded p-2 mb-2">
                        <button
                          className="bg-transparent border-0"
                          onClick={() => decreaseQuantity()}
                          disabled={quantity <= 2}
                        >
                          <AiOutlineMinus />
                        </button>
                        <span className="fw-semibold mx-2">{quantity}</span>
                        <button
                          className="bg-transparent border-0"
                          onClick={() => increaseQuantity()}
                        >
                          <AiOutlinePlus />
                        </button>
                      </div>
                      <Button onClick={onAddToCart} className="mb-2">
                        <HiScissors size={25} /> Add to Cart
                      </Button>
                    </div>
                    <p className="quantity-instruction">
                      <span className="text-danger">*</span>2 YD MIN for all
                      fabric by the yard.
                    </p>
                  </Col>
                </Row>
              </Col>
            </Row>
          </>
        )}
      </Container>
    </MainLayout>
  );
}
