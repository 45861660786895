import React from "react";
import { Table } from "react-bootstrap";

export default function ModalContent() {
  return (
    <Table striped bordered hover className="fill-type">
      <thead>
        <tr></tr>
      </thead>
      <tbody>
        <tr>
          <td></td>
          <td>
            <img
              src="./images/fill-type/bonded-poly-fiber.png"
              className="w-100 h-100 object-fit"
            />
          </td>
          <td>
            <img
              src="./images/fill-type/polyurethane-foam.png"
              className="w-100 h-100 object-fit"
            />
          </td>
          <td>
            <img
              src="./images/fill-type/reticulated-dri-fast-foam.png"
              className="w-100 h-100 object-fit"
            />
          </td>
          <td>
            <img
              src="./images/fill-type/polyster-fiber-batting-wrap.png"
              className="w-100 h-100 object-fit"
            />
          </td>
        </tr>
        <tr>
          <th>Material</th>
          <th>Polyester Fiber Fill</th>
          <th>Polyurethane Foam</th>
          <th>Reticulated Dry-Fast Foam</th>
          <th>Polyester Fiber Batting</th>
        </tr>
        <tr>
          <th>Material</th>
          <td>100% Polyester</td>
          <td>100% Polyurethane</td>
          <td>100% Polyurethane</td>
          <td>100% Polyester</td>
        </tr>
        <tr>
          <th>Density</th>
          <td>1.50lb/ft³ (± 0.10lb/ft³)</td>
          <td>1.80lb/ft³ (± 0.10lb/ft³)</td>
          <td>1.20lb/ft³ (± 0.10lb/ft³)</td>
          <td>0.50lb/ft³ (± 0.10lb/ft³)</td>
        </tr>
        <tr>
          <th>Usage</th>
          <td>Indoor / Outdoor</td>
          <td>Indoor / Outdoor</td>
          <td>Indoor / Outdoor</td>
          <td>Indoor / Outdoor</td>
        </tr>
        <tr>
          <th>Water Retention</th>
          <td>High</td>
          <td>Medium</td>
          <td>Very Low</td>
          <td>Low</td>
        </tr>
        <tr>
          <th>Additional Information</th>
          <td>
            Polyester fiber fill is a vertically layered, heat-bonded polyester
            cushion filling that is 100% free of any bonding agents. Polyester
            fiber fill is NOT recommended for cushions that require quick
            drainage of water and/or fast drying times.
          </td>
          <td>
            Our basic polyurethane foam filling is industry standard
            medium-density closed-cell sheet foam. Polyurethane foam is
            recommended for most boxed edge cushions.
          </td>
          <td>
            Reticulated dry-fast foam is an open-cell, quick-draining and
            fast-drying sheet foam. Reticulated Dry-Fast foam is highly
            recommended for any cushion that will be used in situations where
            frequent and/or quick drainage of water from cushions is essential.
          </td>
          <td>
            Polyester fiber batting is a thin polyester foam wrapper that
            provides approximately 1/2″ to 1-1/2″ of additional low density loft
            to foam filled cushions. Fiber batting is highly recommended for any
            foam filled cushion to add loft and avoid a rigid appearance.
          </td>
        </tr>
      </tbody>
    </Table>
  );
}
