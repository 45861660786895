import React, { useEffect, useState, useRef } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { calculateLaborCost } from "../../../utils";
import { setConfigurations } from "../../../redux/actions";

function ProductMetaInfo({ configurations }) {
  const modelState = useSelector((state) => state.model);
  // const configurations = useSelector((state) => state.configurations);
  const [price, setPrice] = useState(0);
  const dispatch = useDispatch();
  // console.log(modelState);

  useEffect(() => {
    const updatePricing = () => {
      console.log(configurations);
      let object = configurations.prices;
      let sum = 0;
      let sumOthers = 0;
      for (const key in object) {
        if (object.hasOwnProperty(key)) {
          const value = object[key];
          if (key === "ties") {
            sumOthers = sumOthers + value;
          }
          // Check if the value is an object
          else if (typeof value === "object") {
            // Iterate through the nested entries of the object
            for (const nestedKey in value) {
              if (value.hasOwnProperty(nestedKey)) {
                const nestedValue = value[nestedKey];

                // Add the nested value to the sum
                sum += nestedValue;
              }
            }
          } else {
            // Add the top-level value to the sum
            sum += value;
          }
        }
      }
      sum = (sum + sumOthers).toFixed(0);
      // if (configurations?.cushionStructure) {
      //   if (configurations.cushionStructure?.unoDuo) {
      //     let unoDuo = configurations.cushionStructure.unoDuo;
      //     let fabrics = configurations.cushionStructure.fabrics;
      //     if (unoDuo === "uno") {
      //       const values = Object.values(fabrics);
      //       const firstValue = values[0];
      //       const areAllValuesEqual = values.every(
      //         (value) => value === firstValue
      //       );
      //       if (areAllValuesEqual) {
      //         sum = sum * 1.5;
      //       } else {
      //       }
      //     } else if (unoDuo === "duo") {
      //     }
      //   }
      // }
      setPrice(sum);
      dispatch(
        setConfigurations({
          totalPrice: sum,
        })
      );
    };
    updatePricing();
  }, [configurations?.prices]);

  useEffect(() => {
    const updateLaborCost = () => {
      if (configurations.dimensions !== undefined) {
        let laborCost = calculateLaborCost(configurations.dimensions);
        laborCost = Object.assign({}, configurations.prices, {
          laborCost: laborCost,
        });
        let prices = {
          prices: laborCost,
        };
        console.log("Dimensions:", configurations.dimensions);
        console.log("Labor Cost:", laborCost);
        dispatch(setConfigurations(prices));
        console.log("Labor Cost:", configurations);
      }
    };
    updateLaborCost();
  }, [configurations.dimensions]);

  return (
    <div className="product-meta-info-container">
      <div className="d-flex align-center justify-content-between product-info">
        {modelState.modelConfigurations && (
          <h1 className="fs-4 fw-bold text-uppercase mb-0">
            {modelState.modelConfigurations.productTitle}
          </h1>
        )}
        <p className="fs-4 fw-bold mb-0">${price}</p>
      </div>
    </div>
  );
}

function mapStateToProps(state) {
  return {
    configurations: state.configurations,
  };
}

export default connect(mapStateToProps)(ProductMetaInfo);
