import * as yup from "yup";

export const loginSchema = yup.object().shape({
  email: yup.string().email().required("Email required!"),
  password: yup.string().required("Password required!"),
});

export const signupSchema = yup.object().shape({
  firstName: yup.string().required("First Name required!"),
  lastName: yup.string().required("Last Name required!"),
  email: yup.string().email().required("Email required!"),
  password: yup.string().required("Password required!"),
});

export const checkoutSchema = yup.object().shape({
  firstName: yup.string().required("First Name required!"),
  lastName: yup.string().required("Last Name required!"),
  email: yup.string().email("Invalid Email!").required("Email required!"),
  phone: yup.string().required("Phone required!"),
  city: yup.string().required("City required!"),
  state: yup.string().required("State required!"),
  address: yup.string().required("Address required!"),
  postcode: yup.string().required("Postcode required!"),
});

export const newsletterSchema = yup.object().shape({
  EMAIL: yup.string().email("Invalid Email!").required("Email required!"),
});
