import { SET_ACTIVE_FABRIC } from "../actionTypes/actionTypes";

const initialState = {
  show: false,
  data: null,
  filter: {
    
  }
};

const fabricGalleryReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_ACTIVE_FABRIC:
      var newState = {
        show: action.show,
        data: action.fabric
      };
      return Object.assign({},state, newState);
      
    default:
      return state;
  }
};


export default fabricGalleryReducer;