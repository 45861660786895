/**
 * 👉 This component renders page for Success when a product is added to the cart.
 *
 * 📅 Last Modified: 19 - 01 - 2023
 */
import React from "react";
import { FaCartPlus, FaChevronRight, FaPencilAlt } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { addConfiguration } from "../redux/actions";
import { Link } from "react-router-dom";

export default function PgSuccessCart() {
  const state = useSelector((state) => state);
  console.log(state);
  const dispatch = useDispatch();
  return (
    <section className="d-flex vh-100 align-items-center">
      <div className="container mx-auto">
        <div className="row">
          <div className="col-md-12 text-center">
            <h2>
              <FaCartPlus /> Item added to the cart!
            </h2>
            <a href="/" className="btn btn-success me-2">
              <FaChevronRight /> Go to Cart
            </a>
            <a href="/" className="btn btn-primary">
              <FaPencilAlt /> Design another Cushion
            </a>
          </div>
        </div>
      </div>
    </section>
  );
}
